import React, { useState, useReducer, useEffect } from 'react';
import { StyledModalcomponentReportesModalGraph } from '../../Styled';
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/es"; 
import Input from '../../../Input/Input.jsx';
import CustomButton from '../../../Button/CustomButton.tsx';
import Modal from '../../Modal.jsx';



const optionsSelect = [
  { value: 'analisis', label: 'Demora promedio por conjunto de análisis' },
  { value: 'estado', label: 'Cantidad de muestras por estado' },
  { value: 'diagnostico', label: 'Cantidad de muestras por estado de diagnóstico' },
  { value: 'fallas', label: 'Causas de fallas' },
  { value: 'demoras', label: 'Días de demora por órden de trabajo' },
  { value: 'tiempo', label: 'Tiempo medio entre fallas' },
  { value: 'cambios', label: 'Línea de cambios  de estados' }
]

const ReportesModal = (props) => {
  dayjs.locale("es");
  const propsReportes = (datosReportes) => {
    const ver = datosReportes?.button === "ver" ?? false;
    const editar = datosReportes?.button === "editar" ?? false;
    const datos = datosReportes?.datos ?? [];
    return { ver, editar, datos }
  }

  const { ver, editar, datos } = propsReportes(props.datos);
  const [formCompleto, setFormCompleto] = useState(false);
  const [statusModal, setModalStatus] = useState(false);
  const [selectedOptionTipoReporte, setSelectedOptionTipoReporte] = useState(null);


  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    cliente: '',
    planta: '',
    equipo: '',
    inicioPeriodo: props.datos.inicioPeriodo ? dayjs(props.datos.inicioPeriodo) : null,
    finPeriodo: props.datos.finPeriodo ?dayjs(props.datos.finPeriodo) : null,
    tipoReporte: '',
  });

  useEffect(() => {
    const campoValido = campo => form[campo] !== '' && (campo !== 'habilitado' || form[campo]);
    const camposaValidar = ['cliente', 'planta', 'equipo', 'periodo', 'tipoReporte'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      setSelectedOptionTipoReporte(e);
      setForm({ type: "SET_FIELD", field: name, value: value });
    }
  };
  const handleChangeCalendar = (e, nameCalendar) => {
    const name = nameCalendar;
    setForm({ type: "SET_FIELD", field: name, value: e });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  }

  const titulo = () => {
    const titulo = "Generación de Reportes"
    return titulo
  }
  const renderButtons = () => {
    return (
      <div className="divButtons">
        <CustomButton
          onClick={() => {

            if (props.onClick) {
              props.onClick();
            }
          }}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          onClick={() => { setModalStatus(true) }}
        // disabled={!formCompleto}
        />

      </div>
    );
  };

  return (
    <StyledModalcomponentReportesModalGraph>
      <div className="reportes">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <form className="formulario" onSubmit={handleSubmit}>
          <div className="col">
            <div className='aCol'>
              <div className="divLab">
                <p className="label">Fecha de Reporte: <span>Fecha de Reporte</span></p>
              </div>
              <Input
                reactSelect
                // options={unidades}
                // value={selectedOption}
                onChange={(e) => { handleChange(e, "cliente") }}
                // isDisabled={ver && true}
                name="cliente"
                placeholder={ver ? "Cliente" : "Selecciona el cliente"}
              />
              <Input
                reactSelect
                // options={unidades}
                // value={selectedOption}
                onChange={(e) => { handleChange(e, "equipo") }}
                // isDisabled={ver && true}
                name="equipo"
                placeholder={ver ? "Planta" : "Selecciona la planta"}
              />
            </div>
            <div className='bCol'>
              <div className="divLab">
                <p className="label">Autor: <span>Autor</span></p>
              </div>
              <Input
                reactSelect
                // options={unidades}
                // value={selectedOption}
                onChange={(e) => { handleChange(e, "planta") }}
                // isDisabled={ver && true}
                name="planta"
                placeholder={ver ? "Equipo" : "Selecciona el equipo"}
              />
              <div className="divDatePicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>

                  <DatePicker
                    style={{ width: "50%" }}
                    label="Período inicio"
                    value={form.inicioPeriodo}
                    onChange={(newValue) => handleChangeCalendar(newValue, "inicioPeriodo")}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    style={{ width: "50%" }}
                    label="Período fin"
                    value={form.finPeriodo}
                    onChange={(newValue) => handleChangeCalendar(newValue, "finPeriodo")}
                    renderInput={(params) => <TextField {...params} />}
                  />

                </LocalizationProvider>
              </div>

            </div>
          </div>
          <Input
            reactSelect
            options={optionsSelect}
            value={selectedOptionTipoReporte}
            onChange={(e) => { handleChange(e, "tipoReporte") }}
            isDisabled={ver && true}
            name="tipoReporte"
            placeholder={ver ? "Tipo de Reporte"
              : "Selecciona el tipo de Reporte"}
          />
          {renderButtons()}
        </form>
        <Modal
          datos={{ form: form, tipoReporte: selectedOptionTipoReporte }}
          isOpen={statusModal}
          type={"reportesGraph"}
          onClose={() => { setModalStatus(false); }}
        />
      </div>

    </StyledModalcomponentReportesModalGraph>
  )
}

export default ReportesModal