import React, { useState, useEffect, useReducer } from 'react'
import { StyledModalcomponent } from '../../../Styled.jsx';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { updateProtocolos, createProtocolos, getAnalisis, getAnalisisOptions } from '../../../../../api/Configuracion';
import Toast from '../../../../Toast/Toast.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';

const ItemConjuntoAnalisis = (props) => {
  const propsItemConjuntoAnalisis = (datosConjuntoAnalisis) => {
    const ver = datosConjuntoAnalisis?.button === "ver" ?? false;
    const editar = datosConjuntoAnalisis?.button === "editar" ?? false;
    const datos = datosConjuntoAnalisis?.datos ?? [];
    const descripcion = datosConjuntoAnalisis?.descripcion ?? "";
    const protocolos = datosConjuntoAnalisis?.protocolos ?? "";
    return { ver, editar, datos, descripcion, protocolos }
  }


  const { ver, editar, datos, descripcion, protocolos, codigo } = propsItemConjuntoAnalisis(props.datos);
  const [analisis, setAnalisis] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState('');
  const [formCompleto, setFormCompleto] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? '',
    orden: datos.orden ?? '',
    analisis: datos.descripcion ?? '',
    conjuntoAnalisis: datos.conjuntoAnalisis ?? '',
    habilitado: editar || ver
      ? datos.habilitado === "ACTIVE" || datos.habilitado === "SI"
      : true
  });

  useEffect(() => {
    const campoValido = campo => form[campo] !== '' && (campo !== 'habilitado' || form[campo]);
    const camposaValidar = ['orden', 'analisis', 'conjuntoAnalisis'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  useEffect(() => {
    const fetchAnalisis = async () => {
      const res = await getAnalisisOptions();
      let unidades = res?.data?.map(item => ({
        value: item.id,
        label: item.description,
      }));

      const analisisArray = protocolos.map(res => res.analisis);

      if (editar) {
        unidades = unidades?.filter(item => {
          return (
            !analisisArray.includes(item.label) || item.label === datos?.analisis
          );
        });
      } else if (!editar && !ver) {
        unidades = unidades?.filter(item => !analisisArray.includes(item.label));
      }
      if (datos) {
        const matchOption = unidades.find(option => option.label === datos.analisis);
        if (matchOption) {
          setSelectedOption(matchOption);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOption(unidades[0]);
      }

      setAnalisis(unidades);
    };

    fetchAnalisis();
  }, [editar, ver, datos, protocolos]);

  useEffect(() => {
    if (selectedOption) {
      setForm({ type: 'SET_FIELD', field: "analisis", value: selectedOption.value });
    }
  }, [selectedOption]);


  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      setSelectedOption(e);
      setForm({ type: 'SET_FIELD', field: name, value: value });
    }
  };


  const handleSubmit = (e) => {
    //alert("Submit" + JSON.stringify(form));
    e.preventDefault();
    setIsLoading(true);

    let formattedData = {
      "id": form.id,
      "codigo_pro": "EFM001",
      "desc_pro": "No tiene descripcion",
      "orden_pro": form.orden,
      "habilitado_pro": "1",
      "conjunto_id": form.conjuntoAnalisis,
      "analisis_id": form.analisis,
      "created_by": "admin",
      "created_date": new Date(),
      "last_modified_by": "admin",
      "status": form.habilitado ? "SI" : "NO",
      "eliminado": false,
      "tenantId": 1,

    }

    if (editar) {
      updateProtocolos(formattedData).then(res => {
        setSuccess(true);
        setIsLoading(false);
        setMsjToast("Protocolo modificado con exito");
        props.onClick()
      });
    } else {
      createProtocolos(formattedData).then(res => {
        setSuccess(true);
        setIsLoading(false);

        setMsjToast("Protocolo creado con exito");
        props.onClick()
      });
    }

  }
  
  const titulo = () => {
    const titulo = ver ? "Ver análisis del conjunto" : editar ? "Editar análisis del conjunto" : "Crear análisis del conjunto";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!formCompleto}
          />
        )}
      </>
    );
  };

  return (
    <StyledModalcomponent style={{ width: "60%" }}>
      <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />

      <div classname="toast" >
        {success ? <Toast title={msjToast} close={() => setSuccess(false)} /> : null}
        {error ? <Toast type={"error"} title={"Error al " + (editar ? "modificar" : "crear") + "la unidad ?"} close={() => setError(false)} /> : null}
      </div>

      <div className="itemConjuntoAnalisis">
        <div className="divLabel">
          <p className="titulo"> {titulo()}</p>
        </div>

        <div className="divInputsItem" style={{ width: "100%" }}>
          <form className="formulario" onSubmit={handleSubmit} >
            <div className="divPrimerInput">
              <div className="divTituloConjuntoAnalisis">
                <p className="titulo">Conjunto de análisis</p>
                <p className="sub">
                  {/* {form.conjuntoAnalisis ?? ""}{descripcion ?? ""} */}
                  {props.datos.codigo ?? ""} <span className="desc">{descripcion ?? ""}</span>
                </p>
              </div>

              <FormControlLabel
                control={<Switch
                  name="habilitado"
                  disabled={ver}
                  checked={form.habilitado}
                  onChange={handleChange} />}
                label="Habilitar"
              />
            </div>

            <Input
              reactSelect
              options={analisis}
              value={selectedOption}
              onChange={(e) => { handleChange(e, "analisis") }}
              isDisabled={ver && true}
              placeholder={!ver ? "Seleccione el análisis" : "Análisis"}
            />

            <Input
              value={form.orden}
              label="Orden"
              type="number"
              className="inputOrden input"
              variant="outlined"
              name="orden"
              onChange={handleChange}
              disabled={ver && true}
              inputProps={{ min: 0 }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e") {
                  e.preventDefault();
                }
              }}
            />
            <div className="divButtonsItem">
              {renderButtons()}
            </div>
          </form>



        </div>
      </div>
    </StyledModalcomponent>
  )
}

export default ItemConjuntoAnalisis