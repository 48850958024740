import React from 'react'
import { StyledModalSuperAdmin } from '../../../../Styled.jsx';
import CustomButton from '../../../../../Button/CustomButton.tsx';

const ModalDuplicate = (props) => {
  return (
    <StyledModalSuperAdmin
      sx={{
        width: "40%",
        "@media (max-width: 768px)": {
          width: "80%",
          height: "30%",
          overflow: "hidden"
        },
      }}
    >
      <div className="deleteUsuariosSuperAdminModal">
        <div className="divLabel">
          <p className="titulo"> INTERVENCIÓN DUPLICADA </p>
        </div>
        <div className="divBody">
          <p className="label">  Ya existe una intervención del Tipo {props.tipoEvento ?? ""} registrada para el equipo {props.equipo ?? ""}, en la fecha  {props.fecha ?? ""}</p>
        </div>
        <div className="divButtons">

          <div className="buttons">
            <CustomButton
              onClick={props.onClick}
              label={"Cerrar"}
              variant="contained"
              className={"buttonColorDisabled"}
            />

          </div>
        </div>
      </div>
    </StyledModalSuperAdmin>
  )
}

export default ModalDuplicate