import React, { useReducer, useState } from 'react'
import { StyledModalSuperAdmin } from '../../Styled.jsx';
import { fonts, colors } from '../../../../assets/styles/theme.ts';
import {
  FormControlLabel,
  Switch,
  Radio,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Checkbox,
  ListItemText,
  InputLabel,
  styled
} from '@mui/material';
import Input from '../../../Input/Input.jsx';
import CustomButton from '../../../Button/CustomButton.tsx';

const plantasOptions = ["Planta A", "Planta B", "Planta C"];

const UsuariosSuperAdmin = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    nombre: '',
    apellido: '',
    email: '',
    login: '',
    password: '',
    roles: '',
    cliente: '',
    plantas: [],
    habilitado: '',
  });
  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
    }
  };
  const handlePlantasChange = (event) => {
    const { value } = event.target;
    setForm({ type: "SET_FIELD", field: "plantas", value });
  };
  return (
    <StyledModalSuperAdmin>
      <div className="usuariosSuperAdmin">
        <div className="divLabel">
          <p className="titulo">Usuario</p>
        </div>
        <form>
          <div className="divInputs">
            <FormControlLabel
              control={<Switch name="habilitado" checked={form.habilitado} onChange={handleChange} />}
              label="Habilitar"
              sx={{ marginLeft: "auto" }}
            />
            <div className="aDiv">
              <Input
                value={form.codigo}
                label="Nombre"
                classnamediv="divInputNombre"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="nombre"
                onChange={handleChange} />
              <Input
                value={form.apellido}
                label="Apellido"
                classnamediv="divInputApellido"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="apellido"
                onChange={handleChange} />
            </div>
            <div className="bDiv">
              <Input
                value={form.email}
                label="Email"
                classnamediv="divInputEmail"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="email"
                onChange={handleChange} />

            </div>
            <div className="cDiv">
              <Input
                value={form.login}
                label="Login"
                classnamediv="divInputLogin"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="login"
                onChange={handleChange} />
              <Input
                value={form.password}
                label="Password"
                classnamediv="divInputPassword"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="password"
                onChange={handleChange} />
            </div>
            <div className="dDiv">
              <p className="labelDdiv">Roles</p>

              <div className="divRadioButtons">
                <FormControl sx={{ display: "flex" }}>
                  <RadioGroup value={form.roles} onChange={(e) =>
                    handleChange(
                      { target: { name: "roles", value: e.target.value } }
                    )
                  }>
                    <FormControlLabel value="laboratorio" control={<Radio />} label="Laboratorio" />
                    <FormControlLabel value="cliente" control={<Radio />} label="Cliente" />
                    <FormControlLabel value="planta" control={<Radio />} label="Planta" />
                  </RadioGroup>
                </FormControl>
              </div>

            </div>
            <div className="cDiv">

              <div className="cDivInputs">
                <Input
                  reactSelect
                  // options={unidades}
                  // value={selectedOption}
                  onChange={(e) => { handleChange(e, "cliente") }}
                  name="cliente"
                  placeholder="Cliente"
                />
                <FormControlStyled fullWidth>
                  <InputLabel id="plantas-label">Plantas</InputLabel>
                  <Select
                    multiple
                    name="plantas"
                    value={form.plantas}
                    onChange={handlePlantasChange}
                    label="Plantas"
                    labelId="plantas-label"
                    renderValue={(selected) => selected.join(", ")}

                  >
                    {plantasOptions.map((planta) => (
                      <MenuItem key={planta} value={planta}>
                        <Checkbox checked={form.plantas.indexOf(planta) > -1} />
                        <ListItemText primary={planta} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControlStyled>
              </div>
            </div>
          </div>
          <div className="divButtons">
            <div className="buttons">
              <CustomButton
                onClick={props.onClick}
                label={"Cancelar"}
                variant="contained"
                className={"buttonColorDisabled"}
              />
              <CustomButton
                label={"Confirmar"}
                variant="contained"
                className={"buttonColorDefault"}
              />
            </div>

          </div>
        </form>
      </div>
    </StyledModalSuperAdmin>
  )
}

export default UsuariosSuperAdmin

const FormControlStyled = styled(FormControl)`
  label {
    font-family: ${fonts.primary};
    color: ${ colors.blueDark };
    font-size: 17px;
    font-weight: 600;
    margin: 4px 0px;
  }
`