import React from "react";
import styled from "styled-components";
import SuperAdmin from "./Items/SuperAdmin";
import { useAuth } from "../../context/AuthContext";
import DashboardClientes from "./Items/DashboardClientes/DashboardClientes";
import DashboardLaboratorio from "./Items/DashboardLaboratorio/DashboardLaboratorio";
import DashboardPlantas from "./Items/DashboardPlantas/DashboardPlantas";


const Task = () => {
  const { token } = useAuth();
  return (
    <Styled>
      {token?.rol === "SUPERADMIN" && <SuperAdmin />}
      {token?.rol === "CLIENTE" && <DashboardClientes />}
      {token?.rol === "TENANT" && <DashboardLaboratorio />}
      {token?.rol === "PLANTA" && <DashboardPlantas/>}

    </Styled>
  )
}

export default Task;

const Styled = styled.div`
  /* height: 100vh; */
  padding: 0px 20px;
`