import React from "react";
import { Typography, styled, Box } from "@mui/material";
import CardNumber from "../../../../../CompDiagMasivos/CardNumber.tsx";
import { colors, fonts } from "../../../../../../assets/styles/theme.ts";
import RenderImg from "../../../../../RenderImg/RenderImg.tsx";
import Remove from "../../../../../../assets/Icons/Buttons/Remove.svg";
import Input from '../../../../../Input/Input.jsx';

const Card = (props) => {
  return (
    <Styled>
      <div className="divHeader">
        <div className="divCardNumber">
          <Typography className="label">Nro de muestra</Typography>
          <CardNumber value={props.datos.value ?? "25"} />
        </div>
        <div className="divLabelEstado">
          <Typography className="label">Estado :</Typography>
          <Typography className="valueLabel">{props.datos.estado ?? "Peligro"}</Typography>
        </div>
        <div className="divImg">
          <RenderImg
            img={props.datos?.img ?? Remove}
            width="100px"
          />
        </div>
      </div>

      <div className="divBody">
        <div className="divDatos">
          <ul>
            <li>
              <span className="span">Equipo:</span>
              <span className="spann">
                {props.datos.equipo ?? "Plataforma volcable (Cebada)"}
              </span>
            </li>
            <li>
              <span className="span">Punto de Toma:</span>
              <span className="spann">
                {props.datos.puntoToma ?? "M-5001-9857"}
              </span>
            </li>
            <li>
              <span className="span">Lubricante:</span>
              <span className="spann">
                {props.datos.lubricante ?? "Shell Omala 320"}
              </span>
            </li>
            <li>
              <span className="span">Extracción:</span>
              <span className="spann">
                {props.datos.extraccion ?? "Luis Perez - 01/02/2024 11:35"}
              </span>
            </li>
            <li>
              <span className="span">Fecha Análisis:</span>
              <span className="spann">
                {props.datos.fechaAnalisis ?? "05/03/2024"}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="divInputs">
        <Input
          inputProp={true}
          placeholder={"Causa de Falla"}
          onChange={(e) => {
            if (props.onChangeInput) {
              props.onChangeInput(e)
            }
          }}
          // value={form.cliente}
          name={"Causa de Falla"}
          classnamediv="divInputCausaFalla"
          className="input"
        />
      </div>


    </Styled>
  );
};

export default Card;

const Styled = styled(Box)`
display: flex;
flex-direction: column;
gap: 20px;
  .divHeader {
    display: flex;
    gap: 20px;
    align-items: center;
    .divCardNumber{
      display: flex;
      flex-direction: column;
      gap: 5px;
      .label{
        font-family: ${fonts.primary};
        font-size: 14px;
        font-weight: 400;
        text-align: center;
      }
      .input .MuiInputBase-input {
        font-size: 20px;
        background: ${colors.disabled};
        border-radius: 10px;
      }
    }
    .divLabelEstado {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .label {
        font-family: ${fonts.primary};
        font-size: 14px;
        font-weight: 400;
        color: ${colors.blueDark};
      }
      .valueLabel {
        font-family: ${fonts.primary};
        font-size: 24px;
        font-weight: 600;
        color: ${colors.blueDark};
      }
    }
   }
  .divBody {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .divDatos {
      display: flex;
      flex-direction: column;
      ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        li {
          margin-bottom: 10px;
          display: flex;
          .span,
          .spann {
            font-family: "Inter", sans-serif;
          }
          .span {
            color: #545454;
            display: inline-block;
            width: 150px;
            font-size: 12px;
          }
          .spann {
            color: #163c5b;
            font-weight: bold;
            font-size: 13px;
          }
        }
      }
    }
  }
  .divInputs{
    width: 100%;
    .divInputCausaFalla,.input{
      width: 100%;
    }
  }
`;
