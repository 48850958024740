import React, { useReducer } from 'react'
import { Box } from '@mui/material';
import { StyledModalcomponentClientesExternos } from '../../Styled.jsx';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Input from '../../../Input/Input.jsx';
import CustomButton from '../../../Button/CustomButton.tsx';
import Tables from '../../../Tables/Tables.jsx';

const columnsDefaultCfg = [
  { id: "fecha", label: "Fecha" },
  { id: "tipoEvento", label: "Tipo Intervención" },
]

const datosRows = [
  {
    fecha: "fecha",
    tipoEvento: "tipoEvento",
  },
  {
    fecha: "fecha",
    tipoEvento: "tipoEvento",
  },
  {
    fecha: "fecha",
    tipoEvento: "tipoEvento",
  },

]


const IntervencionesClientesExternosModal = (props) => {
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    cliente: props.datos.cliente ?? '',
    planta: props.datos.planta ?? '',
    equipo: props.datos.equipo ?? '',
    tipoDeEvento: props.datos.tipoDeEvento ?? '',
    fecha: props.datos.fecha ?? '',
    detalleDelEvento: props.datos.equipo ?? '',
    observaciones: props.datos.planta ?? '',
  });
  const propsIntervencionesClientesExternos = (datosIntervencionesClientesExternos) => {
    console.log(datosIntervencionesClientesExternos?.datos)
    const ver = datosIntervencionesClientesExternos?.button === "ver" ?? false;
    const editar = datosIntervencionesClientesExternos?.button === "editar" ?? false;
    const datos = datosIntervencionesClientesExternos?.datos ?? [];
    return { ver, editar, datos }
  }
  const { ver, editar, datos } = propsIntervencionesClientesExternos(props.datos);
  console.log(props.datos);
  const titulo = () => {
    const titulo = ver ? "Ver Intervenciones" : editar ? "Editar Intervenciones" : "Crear o editar Intervenciones";
    return titulo
  }
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
          //  disabled={!editar ? !formCompleto : false}
          />
        )}
      </>
    );
  };
  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      setForm({ type: "SET_FIELD", field: name, value: value });
    }
  };

  return (
    <StyledModalcomponentClientesExternos sx={{
      width: "80%",
      padding: "40px",
      '@media (max-width: 768px)': {
        width: "80%",
        overflowY:"scroll",
        height: "80%",
      },
    }}>
      <div className="divLabel" >
        <p className="titulo">{titulo()}</p>
      </div>
      <div className="intervencionesClientesExternos">
        <div className="boxInputs">
          <Box sx={{
            display: "flex", gap: "10px", alignItems: "center", '@media (max-width: 768px)': {
              flexDirection: "column"
            }
          }} >
            <Input
              inputProp={true}
              label={"Cliente"}
              onChange={handleChange}
              value={form.cliente}
              name={"cliente"}
              className="inputDefault"
            />
            <Input
              reactSelect
              placeholder="Planta"
              onChange={(e) => {
                handleChange(e, "planta");
              }}
            />
          </Box>
          <Box sx={{
            display: "flex", gap: "10px", alignItems: "center", '@media (max-width: 768px)': {
              flexDirection: "column"
            }
          }} >
            <Input
              reactSelect
              placeholder="Equipo"
              onChange={(e) => {
                handleChange(e, "equipo");
              }}
            />
            <Input
              reactSelect
              placeholder="Tipo de Evento"
              onChange={(e) => {
                handleChange(e, "tipoDeEvento");
              }}
            />
          </Box>
          <Box sx={{
            display: "flex", gap: "10px", alignItems: "baseline", '@media (max-width: 768px)': {
              flexDirection: "column"
            }
          }} >

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker", "DatePicker", "DatePicker"]}
                className="inputDate"
                sx={{ width: "80%" }}
              >
                <DatePicker
                  className="inputDate"
                  label="Fecha del Evento"
                  sx={{ width: "100%" }}
                  slotProps={
                    <Input
                      className="input inputDate"
                      variant="outlined"
                      size={"medium"}
                    />
                  }
                  onChange={(e) => { handleChange(e) }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Input
              reactSelect
              placeholder="Detalle del Evento"
              onChange={(e) => {
                handleChange(e, "detalleDelEvento");
              }}
            />
          </Box>
          <Box>
            <Input
              inputProp={true}
              label={"Observaciones"}
              onChange={handleChange}
              name={"observaciones"}
              className="inputDefault"
            />
          </Box>
        </div>
        <div className="boxTable">
          <p className="titulo">Eventos Registrados</p>
          <Tables
            columns={columnsDefaultCfg}
            rows={datosRows}
            typeTable={'prop'}
            pagination={true}
            tableColor={false}
          />
        </div>
      </div>
      <div className="divButtonsItem">
        {renderButtons()}
      </div>
    </StyledModalcomponentClientesExternos >
  )
}

export default IntervencionesClientesExternosModal