
import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";


import ProtectedRoute from "./ProtectedRoute";

import Login from "./components/Login/Login";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Task from "./Pages/Task/Task";
//MI CUENTA
import MiCuenta from "./Pages/MiCuenta/MiCuenta.jsx";
//CONFIGURACION
import Unidades from "./Pages/Configuracion/items/Unidades/Unidades.jsx"
import TipoUnidades from "./Pages/Configuracion/items/Unidades/TipoUnidades.jsx";
import Analisis from "./Pages/Configuracion/items/Analisis/Analisis.jsx";
import TipoDeAnalisis from "./Pages/Configuracion/items/Analisis/TipodeAnalisis.jsx"
import ConjuntoDeAnalisis from "./Pages/Configuracion/items/ConjuntoDeAnalisis/ConjuntoDeAnalisis.jsx";
import TipoConjuntoDeAnalisis from "./Pages/Configuracion/items/ConjuntoDeAnalisis/TipoConjuntoDeAnalisis.jsx";
import Lubricantes from "./Pages/Configuracion/items/Lubricantes/Lubricantes.jsx";
import TipoLubricantes from "./Pages/Configuracion/items/Lubricantes/TipoLubricantes.jsx"
import TipoEventos from "./Pages/Configuracion/items/TipoEventos/TipoEventos.jsx";
import Componentes from "./Pages/Configuracion/items/Componentes/Componentes.jsx"
import Metodos from "./Pages/Configuracion/items/Metodos/Metodos.jsx";
import EstadosFinales from "./Pages/Configuracion/items/EstadosFinales/EstadosFinales.jsx";
import CausasDeFalla from "./Pages/Configuracion/items/CausasDeFalla/CausasDeFalla.jsx";
import TiposDeFalla from "./Pages/Configuracion/items/TiposDeFalla/TiposDeFalla.jsx";
import TemplateEmails from "./Pages/Configuracion/items/TemplatesEmails/TemplateEmails.jsx";
import EstadoOrdenesDeTrabajo from "./Pages/Configuracion/items/EstadosOrdenesDeTrabajo/EstadosOrdenesDeTrabajo.jsx";
//CLIENTES
import Clientes from "./Pages/Clientes/items/Clientes/Clientes.jsx";
import Plantas from "./Pages/Clientes/items/Plantas/Plantas.jsx";
import PuntosDeToma from "./Pages/Clientes/items/PuntosDeToma/PuntosDeToma.jsx";
import Equipos from "./Pages/Clientes/items/Equipos/Equipos.jsx";
import Intervenciones from "./Pages/Clientes/items/Intervenciones/Interverciones.jsx";
//MUESTRAS
import Muestras from "./Pages/Muestras/Items/Muestras/Muestras.jsx";
import OrdenesDeTrabajo from "./Pages/Muestras/Items/OrdenesDeTrabajo/OrdenesDeTrabajo.jsx";
import ImportacionDeResultados from "./Pages/Muestras/Items/ImportacionResultados/ImportacionDeResultados.jsx";
import ImportacionImagenes from "./Pages/Muestras/Items/ImportacionImagenes/ImportacionImagenes.jsx";
import CargaManualResultados from "./Pages/Muestras/Items/CargaManualResultados/CargaManualResultados.jsx";
//PROVEEDORES
import Proveedores from "./Pages/Proveedores/Proveedores.jsx";
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
//SUPER ADMIN
import Usuarios from './Pages/SuperAdmin/Usuarios.jsx';
import CfgClientes from './Pages/SuperAdmin/CfgClientes.jsx';

//USUARIO CLIENTES
import InfoMuestras from "./Pages/Usuarios/ClientesExternos/InfoMuestras/InfoMuestras.jsx";
import IntervencionesClientesExternos from "./Pages/Usuarios/ClientesExternos/IntervencionesClientesExternos/IntervencionesClientesExternos.jsx";
//REPORTES
import Reportes from './Pages/Reportes/Reportes.jsx';

function App() {
 
  const [locale, setLocale] = React.useState('esES');
  const theme = useTheme();
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme],
  );
  
  return (
    <AuthProvider>
      <ThemeProvider theme={themeWithLocale}>
        <BrowserRouter>
          <div style={{ minHeight: "100vh" }}>
            <Header />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/task" element={<Task />} />
                <Route path="/micuenta" element={<MiCuenta />} />

                <Route path="/configuracion/unidades" element={<Unidades />} />
                <Route path="/configuracion/tiposdeunidades" element={<TipoUnidades />} />
                <Route path="/configuracion/analisis" element={<Analisis />} />
                <Route path="/configuracion/tiposdeanalisis" element={<TipoDeAnalisis />} />
                <Route path="/configuracion/conjuntodeanalisis" element={<ConjuntoDeAnalisis />} />
                <Route path="/configuracion/tiposdeconjuntodeanalisis" element={<TipoConjuntoDeAnalisis />} />
                <Route path="/configuracion/lubricantes" element={<Lubricantes />} />
                <Route path="/configuracion/tiposdelubricantes" element={<TipoLubricantes />} />
                <Route path="/configuracion/tiposdeeventos" element={<TipoEventos />} />
                <Route path="/configuracion/componentes" element={<Componentes />} />
                <Route path="/configuracion/metodos" element={<Metodos />} />
                <Route path="/configuracion/estadosfinales" element={<EstadosFinales />} />
                <Route path="/configuracion/causasdefalla" element={<CausasDeFalla />} />
                <Route path="/configuracion/tiposdefalla" element={<TiposDeFalla />} />
                <Route path="/configuracion/templatesemails" element={<TemplateEmails />} />
                <Route path="/configuracion/estadosordenesdetrabajo" element={<EstadoOrdenesDeTrabajo />} />
                
                <Route path="/clientes/clientes" element={<Clientes />} />
                <Route path="/clientes/plantas" element={<Plantas />} />
                <Route path="/clientes/puntosdetoma" element={<PuntosDeToma />} />
                <Route path="/clientes/equipos" element={<Equipos />} />
                <Route path="/clientes/intervenciones" element={<Intervenciones />} />
                <Route path="/plantas/plantas" element={<Plantas />} />
                <Route path="/plantas/puntosdetoma" element={<PuntosDeToma />} />
                <Route path="/plantas/equipos" element={<Equipos />} />
                <Route path="/plantas/intervenciones" element={<Intervenciones />} />
                <Route path="/muestras/muestras" element={<Muestras />} />
                <Route path="/muestras/ordenesdetrabajo" element={<OrdenesDeTrabajo />} />
                <Route path="/muestras/importacionderesultados" element={<ImportacionDeResultados />} />
                <Route path="/muestras/importaciondeimagenes" element={<ImportacionImagenes />} />
                <Route path="/muestras/cargamanualderesultados" element={<CargaManualResultados />} />

                <Route path="/proveedores" element={<Proveedores />} />

                <Route path="/reportes" element={<Reportes />} />
                {/* Usuario Clientes */}
                <Route path="/informesmuestras" element={<InfoMuestras />} />
                <Route path="/intervenciones" element={<IntervencionesClientesExternos />} />
                {/* // Super Admin */}
                <Route path="/usuarios" element={<Usuarios />} />
                <Route path="/configuracioncliente" element={<CfgClientes />} />
                
              </Route>
            </Routes>
          </div>
        </BrowserRouter>
        <div style={{ position: "relative", bottom: "0px", width: "100%" }}>
          <Footer />
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
