import React, { useState } from 'react';
import styled from "styled-components";
import Modal from '../../components/Modal/Modal';
import HeaderTable from "../../components/HeaderTable/HeaderTable";
import Tables from '../../components/Tables/Tables';
import { buttonsUsuariosSuperAdmin } from '../../components/ButtonsTable/ButtonsTable';
import { Add } from '@mui/icons-material';
const columns = [
  { id: "apellido", label: "Apellido" },
  { id: "nombre", label: "Nombre" },
  { id: "cliente", label: "Cliente" },
  { id: "plantas", label: "Plantas" },
  { id: "buttons" },
];
const rows = [
  { apellido: "apellido", nombre: "nombre", cliente: "cliente", plantas: "plantas" },
];
const buttonsHeadTable = [
  {
    label: "Crear Nuevo Usuario",
    classname: "buttonHeaderTable",
    size: "medium",
    icon: <Add />,
  },
];
const Usuarios = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [typeModal, setTypeModal] = useState("");

  const handleClickButtonHeader = () => {
    setModalStatus(true);
    setTypeModal("usuariosSuperAdmin");
  }

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setModalStatus(true);
      setTypeModal("deleteUsuariosSuperAdmin")
    }
  };
  return (
    <Styled>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        onClose={() => {
          setModalStatus(false);
        }}
        type={typeModal}
      />
      <HeaderTable
        styledHead="clientes"
        muestras
        title="Usuarios"
        valueBuscador={(value) => console.log(value)}
        datosButtons={buttonsHeadTable}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
      // value={valueSearch}
      // search
      // isClearable={() => { setValueSearch("") }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={rows}
        cRows={20}
        pagination={true}
        // valueBuscador={valueBuscador}
        buttons={buttonsUsuariosSuperAdmin}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />

    </Styled>
  )
}

export default Usuarios;
const Styled = styled.div`
  padding: 20px;
  margin: auto;

  .styledTables {
    //height: 100vh;
  }
`;
