import React, { useReducer, useState, useEffect } from 'react';
import { StyledModalcomponent } from '../../../Styled.jsx';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Toast from '../../../../Toast/Toast.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';

import { getTipoAnalisis, createTipoAnalisis, updateTipoAnalisis } from '../../../../../api/Configuracion';
const TipoDeAnalisis = (props) => {
  const propsTipoAnalisis = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos }
  }
  const { ver, editar, datos } = propsTipoAnalisis(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState('');
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? '',
    codigo: datos.codigo ?? '',
    descripcion: datos.descripcion ?? '',
    abreviatura: datos.abreviatura ?? '',
    habilitado: editar || ver
      ? datos.habilitado === "ACTIVE" || datos.habilitado === "SI"
      : true
  });

  useEffect(() => {
    const campoValido = campo => form[campo] !== '' && (campo !== 'habilitado' || form[campo]);

    //El metodo siguiente desabilita el boton de guardar 
    //si las longitudes de los campos no son validas.

    // const campoValido = campo => {
    //   if (campo === 'codigo') {
    //     return form[campo].length <= 20 && form[campo] !== '';
    //   }
    //   if (campo === 'descripcion') {
    //     return form[campo].length <= 200 && form[campo] !== '';
    //   }
    //   return form[campo] !== '' && (campo !== 'habilitado' || form[campo]);
    // };

    const camposaValidar = ['codigo', 'descripcion'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);


  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (ver) {
      return
    }
    const fieldValue = name === 'habilitado' ? checked : value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.codigo.length > 20) {
      setMsjToast("El codigo es muy largo.");
      setError(true);
      return;
    } else if (form.descripcion.length > 200) {
      setMsjToast("La descripcion es muy larga.");
      setError(true);
      return;
    }

    setIsLoading(true);
    let formattedData = {
      "id": form.id,
      "codigo_tpana": form.codigo.toString(),
      "desc_tpana": form.descripcion,
      "abrev_tpana": form.abreviatura,
      "created_by": "dasfdasd",
      "created_date": new Date(),
      "status": form.habilitado ? "SI" : "NO",
      "tenantId": 1,//sacar tenant
      "eliminado": false,

    }

    if (editar) {
      updateTipoAnalisis(formattedData).then(res => {
        setIsLoading(false);
        props.onClick({ msj: "Tipo de análisis modificado con éxito", status: "success" });


      }).catch((err) => {
        setIsLoading(false);
        props.onClick({ msj: "No se Pudo editar el tipo de análisis", status: "error" });
      });
    } else {
      createTipoAnalisis(formattedData).then(res => {

        setIsLoading(false)
        props.onClick({ msj: "Tipo de análisis creada con éxito", status: "success" });

      }).catch((err) => {
        setIsLoading(false);
        props.onClick({ msj: "No se Pudo crear el tipo de análisis", status: "error" });
      });
    }

  }

  const titulo = () => {
    const titulo = ver ? "Ver tipo análisis" : editar ? "Editar tipo análisis" : "Crear tipo de análisis";
    return titulo
  }

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!formCompleto}
          />
        )}
      </>
    );
  };

  return (
    <StyledModalcomponent>
      <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />

      <div classname="toast" >
        {success ? <Toast title={msjToast} timeClose={3000} close={() => setSuccess(false)} /> : null}
        {error ? <Toast type={"error"} title={msjToast} timeClose={3000} close={() => setError(false)} /> : null}
      </div>

      <div className="tipoAnalisis">
        <div className="divLabel">
          <p className="titulo"> {titulo()}</p>
        </div>
        <div className="divInputsItem">
          <form className="formulario" onSubmit={handleSubmit} >
            <div className="divPrimerInput">
              <Input
                value={form.codigo}
                label="Código"
                className="inputCodigo input"
                variant="outlined"
                size={"medium"}
                name="codigo"
                onChange={handleChange}
                disabled={ver}
              />
              <FormControlLabel
                control={<Switch name="habilitado" disabled={ver} checked={form.habilitado} onChange={handleChange} />}
                label="Habilitar"
              />
            </div>

            <Input
              value={form.descripcion}
              label="Descripción"
              className="inputDesc input"
              variant="outlined"
              size={"medium"}
              name="descripcion"
              disabled={ver}
              onChange={handleChange}
            />
            <Input
              value={form.abreviatura}
              label="Abreviatura"
              className="inputDesc input"
              variant="outlined"
              size={"medium"}
              disabled={ver}
              name="abreviatura"
              onChange={handleChange}
            />
            <div className="divButtonsItem">
              {renderButtons()}
            </div>
          </form>



        </div>
      </div>
    </StyledModalcomponent>
  )
}

export default TipoDeAnalisis