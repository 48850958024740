import React, { useReducer, useState } from 'react';
import { StyledModalcomponentClientesExternos } from '../../../Styled';
import { TextField } from '@mui/material';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Card from './Items/Card';
import CarouselCustom from '../../../../Carousel/CarouselCustom.tsx';
import Tables from '../../../../Tables/Tables.jsx';
import Modal from '../../../Modal.jsx';

const ViewMuestras = (props) => {
  const [statusModal, setModalStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    diagnostico: '',
    apellido: '',
    sugerencia: '',
  });

  const columns = [
    { id: "analisis", label: "Análisis", },
    { id: "resultado", label: "Resultado" }
  ]
  const rows = [
    { analisis: "Contenido de Agua y Sedimentos" },
  ]

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
    }
  };
  return (
    <StyledModalcomponentClientesExternos sx={{
      width: "70%",
      height: "80%",
      overflowY: "auto",
      '@media (max-width: 768px)': {
        width: "90%",
        height: "80%",
        overflowY: "auto",
      },
    }}>
      <div className="viewMuestras">
        <div className="headerDiv">
          <div className="divCard">
            <Card datos={props.datos ?? ""}
              onChangeInput={(e) => { handleChange(e) }}
            />
          </div>
          <div className="divCarousel">
            <div className="divButtons">
              <CustomButton
                onClick={props.onClick}
                label={"Cancelar"}
                variant="contained"
                className={`buttonColorDisabled`}

              />
              <CustomButton
                onClick={() => { setModalStatus(true) }}
                label={"Imprimir"}
                variant="contained"
                className={`buttonColorDefault`}
              />

            </div>
            <CarouselCustom />
          </div>
        </div>
        <div className="divInputsViewMuestras">
          <TextField
            id="outlined-multiline-flexible"
            label="Diagnóstico"
            placeholder="Diagnóstico"
            name={"diagnostico"}
            multiline
            maxRows={4}
            onChange={handleChange}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Sugerencias"
            placeholder="Sugerencias"
            name={"sugerencia"}
            multiline
            maxRows={4}
            onChange={handleChange}
          />

        </div>
        <div className="divTable">
          <Tables
            typeTable={"prop"}
            columns={columns}
            rows={rows}
            cRows={6}
            pagination={true}
          />
        </div>
      </div>
      <Modal
        // datos={data}
        // labelModal={labelModal}
        isOpen={statusModal}
        onClose={() => setModalStatus(false)}
        type={"modalDuplicate"}
      />
    </StyledModalcomponentClientesExternos>
  )
}

export default ViewMuestras