export const colors = {
  turquoise: "#66BBD3",
  lightGray: "#DBE5E8",
  smokeWhite: "#F7F7F8",
  steelBlue: "#547192",
  blueDark: "#163C5B",
  blueColumnTable: "#102d3d",
  blueLight: "#AFCCD5",
  disabled: "rgba(0, 0, 0, 0.12)",
  white: "#FFFFFF",
  darkGray: "#606060",
  darkGrayDos: "#545454",
  success: "#50A449",
  error: "#D44B4B",
  radiantLime: "#D9DD1D",
  selectInput: "rgba(190, 186, 186, 0.87)"
}
export const fonts = {
  primary: "Inter",
  secondary: "Roboto"
};