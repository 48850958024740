import React, { useState } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import ImgIndex from '../../assets/ImgIndex.png';
import LimsOil from '../../assets/Login/LimsOil.png';

const CarouselCustom = (props) => {
  const images = [
    ImgIndex,
    LimsOil,
    ImgIndex,
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Styled >

      <Box
        component="img"
        src={props.images? props.images[currentIndex] : images[currentIndex] }
        alt={`Image ${currentIndex + 1}`}
        sx={{
          padding: "5px 0px",
          width: '100%', 
          maxHeight: '80%', 
          objectFit: 'contain', 
        }}
      />

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 0 }}>
        <IconButton onClick={handlePrev}>
          <ArrowBackIos />
        </IconButton>
        <IconButton onClick={handleNext}>
          <ArrowForwardIos />
        </IconButton>
      </Box>
    </Styled>
  );
};

export default CarouselCustom;

const Styled = styled(Box)`
  width: 100%;
  height: 91%;
  text-align: center; 
  position: relative; 
  margin: 0 auto;
  border-radius: var(--1, 8px);
  border: var(--borderRadius, 4px) solid #66bbd3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
`;