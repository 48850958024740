import React, { useState } from 'react';
import { StyledModalcomponentReportesModalGraph } from '../../Styled.jsx';
import CustomButton from '../../../Button/CustomButton.tsx';
import Print from "../../../../assets/Icons/Buttons/Muestras/Print.svg";
import { RenderImgStyled } from '../../../Tables/TablesStyled.js';
import BarChartComponent from '../../../BarChart/BarChartComponent.jsx';
import { useMediaQuery, Divider } from "@mui/material";
import dayjs from 'dayjs';

const ReportesGraphModal = (props) => {
  const titulo = () => {
    const titulo = "Generación de Reportes"
    return titulo
  }
  const renderButtons = () => {
    return (
      <div className="divButtons" style={{ marginLeft: "auto", padding: "20px 0px" }}>
        <CustomButton
          onClick={() => {

            if (props.onClick) {
              props.onClick();
            }
          }}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
          size="small"
        />

        <CustomButton
          label={"Imprimir"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          onClick={() => { alert() }}
          size="small"
          startIcon={
            <RenderImgStyled img={Print}
              style={{ padding: "5px" }} />
          }
        // disabled={!formCompleto}
        />

      </div>
    );
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const renderBarChart = () => {
    // analisis = Demora promedio por conjunto de análisis
    // estado = Cantidad de muestras por estado
    // diagnostico = Cantidad de muestras por estado de diagnóstico
    // fallas = Causas de fallas
    // demoras = Días de demora por órden de trabajo
    // tiempo = Tiempo medio entre fallas
    // cambios = Línea de cambios de estados
    let defaultDataReportes;
    let footerLabel;
    let styled;
    let yDecimal;
    let type;
    let seriesTiempo;
    let columns;
    let rows;
    let captionTable;
    let captionLabel;
    switch (props.datos.form.tipoReporte) {
      case "estado":
        defaultDataReportes = [
          { label: "Normal", data: [10, 30], color: "#82fc00" },
          { label: "Precaucion", data: [40, 50], color: "#fceb00" },
          { label: "Peligro", data: [50, 70], color: "#fc0000" },
        ];
        footerLabel = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        yDecimal = false;
        styled = "reportesEstado"
        break;
      case "analisis":
        defaultDataReportes = [
          { label: "01- Conjunto basico", value: 12.33 },
          { label: "02- Conjunto completo", value: 15.55 },
        ];
        footerLabel = ['01- Conjunto básico', '02- Conjunto completo']
        columns = [
          { id: "cliente", label: "Cliente" },
          { id: "totalMuestras", label: "Total de muestras" },
          { id: "promedioDias", label: "Promedio días" },
        ];
        rows = [
          [
            { cliente: "Cliente 1", totalMuestras: "10", promedioDias: "360" },
            { cliente: "Cliente 2", totalMuestras: "12", promedioDias: "360" },
            { cliente: "Cliente 3", totalMuestras: "6", promedioDias: "250" },
          ],
          [
            { cliente: "Cliente 1", totalMuestras: "66", promedioDias: "360" },
            { cliente: "Cliente 2", totalMuestras: "05", promedioDias: "240" },
            { cliente: "Cliente 3", totalMuestras: "6", promedioDias: "250" },
          ],
        ];
        
        captionTable = true
        captionLabel = ["01- Conjunto básico", "02- Conjunto completo"]
        styled = "reportesAnalisis"
        type = "analisis"
        break;
      case "diagnostico":
        defaultDataReportes = [
          { label: "Pendiente", data: [5.75], color: "#fc0000" },
          { label: "Publicada", data: [3, 4, 5], color: "#2efc00" },
        ];
        footerLabel = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        styled = "reportesDiagnostico"
        yDecimal = false;
        break;
      case "fallas":
        type = "fallas";
        styled = "reportesCausasDeFalla";
        defaultDataReportes = [
          {
            data: [
              { id: 0, value: 10, label: "Falla 1" },
              { id: 1, value: 15, label: "Falla 2" },
              { id: 2, value: 20, label: "Falla 3" },

            ],
          },
        ];
        break;
      case "demoras":
        defaultDataReportes = [
          { label: "Normal", value: 10 },
          { label: "Precaucion", value: 15 },
          { label: "Peligro", value: 20 },
          { label: "Normall", value: 10 },
          { label: "Pre", value: 15 },
          { label: "Pel", value: 20 },
        ];
        footerLabel = ['Normal', 'Precaucion', 'Peligro', "Normall", "Pre", "Pel"]
        type = "demoras"
        styled = "reportesCausasDemoras"
        break;
      case "tiempo":
        defaultDataReportes = [
          "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
          "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
        seriesTiempo = [
          [36, 30, 42, 33,],
        ];
        columns = [
          { id: "mes", label: "Mes" },
          { id: "cantidadMuestras", label: "Cantidad de muestras" },
          { id: "horasoperacion", label: "Horas operación" },
          { id: "tmbf", label: "TMBF" },
        ];
        rows = [
          { mes: "Enero", cantidadMuestras: "10", horasoperacion: "360", tmbf: "36" },
          { mes: "Febrero", cantidadMuestras: "12", horasoperacion: "360", tmbf: "30" },
          { mes: "Marzo", cantidadMuestras: "6", horasoperacion: "250", tmbf: "42" },
          { mes: "Abril", cantidadMuestras: "15", horasoperacion: "500", tmbf: "33" },
        ];
        type = "tiempo"
        styled = "reportesCausasTiempo"
        break;
      case "cambios":
        columns = [
          { id: "puntoDeToma", label: "Punto de toma" },
        ];
        rows = [
          { puntoDeToma: "puntoDeToma 1", "01/01/2024": <div style={{ background: "red", padding: "5px" }}>2580</div> },
          { puntoDeToma: "puntoDeToma 2", "10/01/2024": <div style={{ background: "green", padding: "5px" }}>3679</div> },
          { puntoDeToma: "puntoDeToma 3", "20/01/2024": <div style={{ background: "orange", padding: "5px" }}>3679</div> },
        ];


        let fechaInicio = props.datos.form.inicioPeriodo?.format("MM/DD/YYYY");
        let fechaFin = props.datos.form.finPeriodo?.format("MM/DD/YYYY");
        let fechaActual = dayjs(fechaInicio);
        const fechas = [];

        while (fechaActual <= dayjs(fechaFin)) {
          fechas.push(fechaActual.format('DD/MM/YYYY'));
          fechaActual = fechaActual.add(1, 'day');
        }
        fechas.forEach(fecha => {
          columns.push({
            id: fecha,
            label: fecha,
          });
        });
        type = "cambios";
        styled = "reportesCausasCambios";
        break;
      default:
        return null;
    }

    return (
      <BarChartComponent
        columns={columns}
        rows={rows}
        caption={captionTable}
        labelCaption={captionLabel}
        series={defaultDataReportes}
        seriesTiempo={seriesTiempo}
        footerLabel={footerLabel}
        width={!isMobile ? 1500 : 900}
        height={!isMobile ? 700 : 500}
        styled={styled}
        yDecimal={yDecimal}
        type={type}
      />
    );
  };
  return (
    <StyledModalcomponentReportesModalGraph sx={{ height: "90%", overflowY: "auto" }}>
      <div className="reportesGraphModal">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>

        <div className="divHeader">
          <div className="aCol">
            <p className="label">Fecha de Reporte:<span className="span"></span></p>
            <p className="label">Autor:<span className="span"></span></p>
          </div>
          <div className="bCol">
            <p className="label">Tipo de Reporte:</p>
            <div className="divTipo"><p className="label">{props.datos.tipoReporte?.label ?? ""}</p></div>
          </div>

        </div>
        <div className="divBody">
          <div className="divLabelBody">
            <div className="acolLabel">
              <p className="label">Cliente:<span className="span"></span></p>
              <p className="label">Planta:<span className="span"></span></p>
            </div>
            <div className="bcolLabel">
              <p className="label">Equipo:<span className="span"></span></p>
              <p className="label">Período:<span className="span"></span></p>
            </div>
          </div>

          <div className="ccolButtons">
            {props.datos.form.tipoReporte === "analisis" &&
              <p className="label">
                NOTA: se toma como inicio la Fecha de notificación de la muestra y como fin la Fecha de Publicación
              </p>
            }
            {props.datos.form.tipoReporte === "demoras" &&
              <p className="label">
                NOTA: El cálculo se realiza desde la fecha de notificación al Cliente hasta la fecha de finalización.
              </p>
            }
            {props.datos.form.tipoReporte === "tiempo" &&
              <p className="label">
                NOTA: Se toman para el cálculo.....
              </p>
            }
            {props.datos.form.tipoReporte === "cambios" &&
              <p className="label">
                NOTA: Cada cuadro indica en su interior, el número de muestra correspondiente y los colores representan el estado de la muestra..
              </p>
            }
            {renderButtons()}
          </div>
        </div>
        <Divider />
        <div className="divBarChart">

          {props.datos.form.tipoReporte === "fallas" &&
            <p className="titulo" style={{ textAlign: "center", width: "95%" }}>
              Cantidad de fallas</p>
          }
          {props.datos.form.tipoReporte === "demoras" &&
            <p className="titulo" style={{ textAlign: "center", width: "95%" }}>
              Días de demora por OT</p>
          }

          {renderBarChart()}
        </div>
        <Divider />

      </div>

    </StyledModalcomponentReportesModalGraph >
  )
}

export default ReportesGraphModal;