import React, { useReducer } from 'react'
import { StyledModalSuperAdmin } from '../../components/Modal/Styled.jsx';
import { FormControlLabel, Switch } from '@mui/material';
import Input from '../../components/Input/Input';
import DropZone from '../../components/DropZone/DropZone.jsx';
import CustomButton from '../../components/Button/CustomButton.tsx';
const CfgClientes = (props) => {
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    codigo: '',
    denominacion: '',
    direccion: '',
    telefono: '',
    email: '',
    paginaWeb: '',
    sizeImg: '',
    pathImg: '',
    linkManualUsuario: '',
    habilitado: '',
  });
  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;

    }
  };
  return (
    <StyledModalSuperAdmin sx={{ width: "90%" }}>
      <div className="cfgClientes">
        <div className="divLabel">
          <p className="titulo"> Configuración Cliente</p>
        </div>
        <form>
          <div className="divInputs">
            <div className="aDiv">
              <Input
                value={form.codigo}
                label="Código"
                classnamediv="divInputCodigo"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="codigo"
                onChange={handleChange} />
              <Input
                value={form.denominacion}
                label="Denominación"
                classnamediv="divInputDenominacion"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="denominacion"
                onChange={handleChange} />
            </div>
            <div className="bDiv">
              <Input
                value={form.direccion}
                label="Dirección"
                classnamediv="divInputDireccion"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="direccion"
                onChange={handleChange} />
              <Input
                value={form.telefono}
                label="Telefono"
                classnamediv="divInputTelefono"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="telefono"
                onChange={handleChange} />
            </div>
            <div className="cDiv">
              <Input
                value={form.email}
                label="Email"
                classnamediv="divInputEmail"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="email"
                onChange={handleChange} />
            </div>
            <div className="dDiv">
              <Input
                value={form.paginaWeb}
                label="Página Web"
                classnamediv="divInputPaginaWeb"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="paginaWeb"
                onChange={handleChange} />
              <Input
                value={form.sizeImg}
                label="Tamaño Máximo de Imágenes"
                classnamediv="divInputSizeImg"
                className="input"
                style={{ width: "100%" }}
                variant="outlined"
                name="sizeImg"
                onChange={handleChange} />
            </div>
            <div className="cDiv">
              <DropZone sx={{ width: "60%" }} />
              <div className="cDivInputs">
                <Input
                  value={form.pathImg}
                  label="Path Imágenes"
                  classnamediv="divInputPathImg"
                  className="input"
                  style={{ width: "100%" }}
                  variant="outlined"
                  name="pathImg"
                  onChange={handleChange} />
                <Input
                  value={form.linkManualUsuario}
                  label="Link Manual del Usuario"
                  classnamediv="divInputLinkManualUsuario"
                  className="input"
                  style={{ width: "100%" }}
                  variant="outlined"
                  name="linkManualUsuario"
                  onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="divButtons">
            <div className="buttons">
              <CustomButton
                onClick={props.onClick}
                label={"Cancelar"}
                variant="contained"
                className={"buttonColorDisabled"}
              />
              <CustomButton
                label={"Confirmar"}
                variant="contained"
                className={"buttonColorDefault"}
              />
            </div>
            <FormControlLabel
              control={<Switch name="habilitado" checked={form.habilitado} onChange={handleChange} />}
              label="Habilitar"
              sx={{ marginLeft: "auto" }}
            />
          </div>
        </form>
      </div>
    </StyledModalSuperAdmin>
  )
}

export default CfgClientes