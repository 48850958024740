import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { StyledModalcomponentMuestras } from "../../../Styled.jsx";
import { colors, fonts } from "../../../../../assets/styles/theme.ts";
import CustomButton from "../../../../Button/CustomButton.tsx";



const Confirmacion = (props) => {


  const handleClose = (result) => {
    if (props.onClick) {
      props.onClick(result); 
    }
  };

  return (
    <StyledModalcomponentMuestras
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "20px",
        width: "50%",
      }}
    >
      <div className="divLabel">
        <p className="titulo">CONFIRMACIÓN</p>
      </div>

      {props.reasignar ? (
        <BoxStyled>
          <Typography>
            ATENCIÓN! Seleccionó la opción
            <span className="span">“Reasignar estados”</span>, con lo cual se
            perderán los estados previamente definidos en las muestras y serán
            reemplazados por el estado actual.
          </Typography>
        </BoxStyled>
      ) : (
        <BoxStyled>
          <Typography>
            Las muestras seleccionadas y sin estado previo definido, serán
            actualizadas con el estado actual.
          </Typography>
        </BoxStyled>
      )}
      <BoxFooter>
        <Typography>Confirma la operación?</Typography>
        <Box>
        <CustomButton
            label={"Si"}
            className={`buttonColorDisabled`}
            onClick={() => handleClose(true)}
          />
          <CustomButton
            label={"No"}
            className={`buttonColorDefault`}
            onClick={() => handleClose(false)}
          />
        </Box>
      </BoxFooter>
    </StyledModalcomponentMuestras>
  );
};

export default Confirmacion;

const BoxStyled = styled(Box)`
  font-family: ${fonts.primary};
  font-weight: 400;
  font-size: 15px;
  border-radius: 8px;
  border: 4px solid ${colors.radiantLime};
  padding: 20px;
  .span {
    font-weight: 600;
  }
`;
const BoxFooter = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  gap: 20px;
  .MuiTypography-root {
    font-family: ${fonts.primary};
    font-weight: 500;
    font-size: 16px;
  }
  .MuiBox-root {
    display: flex;
    gap: 10px;
  }
`;
