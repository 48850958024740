import React, { useReducer, useState, useEffect } from 'react';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CardNumber from "../../../../CompDiagMasivos/CardNumber.tsx";
import Warning from "../../../../../assets/Icons/Buttons/Muestras/warning.svg";
import BoxResult from "../../../../BoxResult/BoxResult.tsx";
import Modal from "../../../../Modal/Modal.jsx";
import { RenderImgStyled } from '../../../../Tables/TablesStyled.js';
import { StyledModalcomponentMuestras } from '../../../Styled.jsx';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { execDiagnosticoMasivo, getEstadosFinalesOptions, getMuestraContadores, getMuestrasByOrden, lookupMensajePredefinidoByEstadoFinal } from '../../../../../api/Configuracion.js';
import Preloader from '../../../../Preloader/Preloader.jsx';
import Toast from '../../../../Toast/Toast.jsx';

const DiagMasivoModal = (props) => {
  const [formCompleto, setFormCompleto] = useState(false);
  const [modalConfirmacion, setModalConfirmacion] = useState(false);
  const [reasignar, setReasignar] = useState(false);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    cliente: '',
    ordenTrabajo: '',
    mensajePredefinido: '',
    diagnostico: '',
    sugerencias: '',
    estadoFinal: '',
    fechaAnalisis: ''
  });

  const [initialData, setInitialData] = useState(null);
  const [estadosFinales, setEstadosFinales] = useState(null);
  const [selectedEstadoFinal, setSelectedEstadoFinal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mensajesPredefinidos, setMensajesPredefinidos] = useState([]);
  const [selectedMensajePredefinido, setSelectedMensajePredefinido] = useState(null);
  const [msjDiagnostico, setMsjDiagnostico] = useState("");
  const [msjSugerencias, setMsjSugerencias] = useState("");
  const [contadores, setContadores] = useState(null);
  const [muestras, setMuestras] = useState(null);
  const [fecha, setFecha] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  
  useEffect(() => {
    setInitialData(props.datos.datos);
    fetchEstadosFinales();
    fetchContadores();
    fetchMuestrasByOrden();
  },[])

  useEffect(() => {
    if(selectedEstadoFinal === null) return;
    setSelectedMensajePredefinido(null);
    fetchMensajesPredefinidos(selectedEstadoFinal.value);
  },[selectedEstadoFinal])

  useEffect(() => {
    const campoValido = campo => form[campo] !== '';
    const camposaValidar = ['cliente', 'ordenTrabajo', 'mensajePredefinido',
      'diagnostico',
      'sugerencias',
      'estadoFinal',
      'fechaAnalisis'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const fieldValue = value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };

  const fetchEstadosFinales = async (idEstadoFinalActual) => {
      const res = await getEstadosFinalesOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_efm,
        image: item.imagen_efm,
        color: item.color_efm
      }));
      setEstadosFinales(unidades);
      setIsLoading(false);
    };

  const fetchContadores = async () => {
    const res = await getMuestraContadores(props.datos.datos.orden.id);
    setContadores(res.data);
  }

  const fetchMuestrasByOrden = async () => {
    const res = await getMuestrasByOrden(props.datos.datos.orden.id);
    setMuestras(res.data);
  }

  const fetchMensajesPredefinidos = async (idEstadoFinalActual) => {
      const res = await lookupMensajePredefinidoByEstadoFinal(idEstadoFinalActual);
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.nombre_m_pre,
        sugerencia: item.sugerencia_m_pre,
        diagnostico: item.diagnostico_m_pre
      }));
      setMensajesPredefinidos(unidades);
    }

  const handleSubmit = (e) => {
    e.preventDefault();
  }
  const titulo = () => {
    const titulo = "Diagnóstico Masivo"
    return titulo
  }
  const handleClickCancelar = () => {
    if (props.onClick) {
      props.onClick();
    }

  };

  const handleButtonEstado = (estado) => {
    //console.log("Estado", estado)
    setSelectedMensajePredefinido(null)
    setSelectedEstadoFinal(estado)
    fetchMensajesPredefinidos(estado.value)
  }

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={handleClickCancelar}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          // disabled={!formCompleto}
          onClick={() => {
            setModalConfirmacion(true);
            //setReasignar(false);
          }}
          disabled={selectedEstadoFinal === null || fecha === null || msjDiagnostico === '' || msjSugerencias === ''}
        />
      </>
    );
  };

  const handleModalClose = async (result) => {
    //console.log(result); // Aquí capturamos true o false
    setModalConfirmacion(false) 

    if(result) {
      if(contadores.totalMuestras < 1) {
        // No hay muestras, no pasa nada

      setMsjToast("No hay muestras para diagnosticar.")
      setError(true);

      } else {
        // Se envio el diagnostico masivo
      setIsLoading(true);

      //console.log(selectedEstadoFinal);
      //console.log(initialData.orden.id, {estadoFinalId: selectedEstadoFinal?.value, fechaAnalisisMue: fecha, diagnosticoMue: msjDiagnostico, sugerenciasMue: msjSugerencias, reasignar: reasignar})
      await execDiagnosticoMasivo(initialData.orden.id, {estadoFinalId: selectedEstadoFinal?.value, fechaAnalisisMue: fecha, diagnosticoMue: msjDiagnostico, sugerenciasMue: msjSugerencias, reasignar: reasignar})
      
      fetchContadores();
      fetchMuestrasByOrden();

      setMsjToast("El diagnostico masivo fue satisfactorio.")
      setSuccess(true);

      setIsLoading(false);
      }

    } else {
      // Se cancelo el diagnostico masivo, no pasa nada
    }
  };

  return (
    <StyledModalcomponentMuestras sx={{
      width: "95%",
      padding: "20px",
      '@media (max-width: 768px)': {
        padding: "10px",
        width: "90%",
        height: "90%",
      },
    }}>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />
      <div classname="toast">
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={msjToast}
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <Modal
        isOpen={modalConfirmacion}
        //datos={{estadoFinalId: selectedEstadoFinal?.id, fechaAnalisisMue: form?.fechaAnalisis, diagnosticoMue: msjDiagnostico, sugerenciasMue: msjSugerencias, reasignar: reasignar}}
        type={"diagMasivoConfirmacion"}
        //ordenId={initialData?.orden.id}
        reasignar={reasignar}
        onClose={handleModalClose}
      />
      <div className="diagMasivo">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <form className="formulario" onSubmit={handleSubmit}>
          <div className="divBody">
            <div className="aCol">
              <Input
                label="Cliente"
                className="input"
                name="cliente"
                value={initialData !== null ? initialData.cliente.label : ""}
                disabled
              />
              <Input
                label="Orden de Trabajo"
                className="input"
                name="ordenTrabajo"
                value={initialData !== null ? initialData.orden.denominacion : ""}
                disabled
              />

              <div className="divEstadoFinal">
              <Input
                  reactSelect={true}
                  placeholder={"Estado Final"}
                  options={estadosFinales}
                  value={selectedEstadoFinal}
                  onChange={(e) => {
                    setSelectedEstadoFinal(e);
                  }}
                />

                <div className="divImagenes">

                {estadosFinales ? estadosFinales.map((estado, index) => (
                  <div className="divImagen" onClick={() => { handleButtonEstado(estado) }} style={selectedEstadoFinal === estado ? { border: `2px solid ${estado.color}` } : {}}>
                    <img src={`data:image/png;base64,${estado.image}`} style={{ width: '75px', height: '75px' }} />
                  </div>
                )) : <></>}
                </div>
              </div>
              <div className="divFechaAnalisis">

                <div className="divDateSwitch"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker", "DatePicker", "DatePicker"]}
                    >
                      <DatePicker
                        className="inputDate"
                        label="Fecha de Análisis"
                        // disabled={ver}
                        // defaultValue={form}
                        // value={fechaseleccionada}
                        slotProps={
                          <Input
                            className="input inputDate"
                            variant="outlined"
                            size={"medium"}
                          />
                        }
                        onChange={(e) => { setFecha(e) }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <FormControlLabel
                    control={<Switch name="reasignar"
                      onChange={handleChange}
                      style={{ margin: "0px" }}
                      onClick={() => {
                        //setModalConfirmacion(true);
                        setReasignar(!reasignar);
                      }}
                    />}
                    label="Reasignar estados"
                  />
                </div>

                <div className="divCard">
                  <RenderImgStyled img={Warning} style={{ height: "25px" }} />
                  <p className="label">ATENCIÓN! Al seleccionar la opción “Reasignar estados”, se perderán los estados anteriores</p>
                </div>


              </div>
            </div>
            <div className="bCol">
            <Input
                  reactSelect={true}
                  placeholder={"Mensaje Predefinido"}
                  options={mensajesPredefinidos}
                  value={selectedMensajePredefinido}
                  onChange={(e) => {
                    setSelectedMensajePredefinido(e);
                    setMsjDiagnostico(e.diagnostico)
                    setMsjSugerencias(e.sugerencia)
                  }}
                />
              <Input
                id="outlined-multiline-static"
                label="Diagnóstico"
                multiline
                rows={4}
                defaultValue="Default Value"
                className="input"
                name="diagnostico"
                onChange={(e) => {
                  setMsjDiagnostico(e.target.value);
                }}
                value={msjDiagnostico}
              />
              <Input
                  id="outlined-multiline-static"
                  label={"Sugerencias"}
                  placeholder={"Sugerencias"}
                  multiline
                  rows={4}
                  className="input"
                  defaultValue="Default Value"
                  value={msjSugerencias}
                  name="sugerencias"
                  onChange={(e) => {
                    setMsjSugerencias(e.target.value);
                  }}
                />

            </div>
          </div>

          <div className="cCol">
            <div className='divCardNumber'>
              <CardNumber label="Total de Muestras" value={contadores !== null ? contadores.totalMuestras : 0} />
              <CardNumber label="Con estado asignado" value={contadores !== null ? contadores.conEstado : 0} />
              <CardNumber label="Cantidad a modificar" value={contadores !== null ? contadores.totalMuestras - contadores.conEstado : 0} active={true} /*onClick={() => alert()}*/ />
            </div>
            <div className="divButtons">
              {renderButtons()}
            </div>
          </div>
          <div className="divBoxResult">
          {muestras ? muestras.map((muestra, index) => (
              <BoxResult
              backgroundColor={muestra.estado.color_efm !== "" ? muestra.estado.color_efm : "#DBE5E8"}
              label={muestra.muestra_id}
              sub={muestra.estado.desc_efm.length > 10 ? muestra.estado.desc_efm.substring(0, 10) + "..." : muestra.estado.desc_efm}
              icon
            />
          )) : <></>}
          </div>
        </form>
      </div>
    </StyledModalcomponentMuestras>
  )
}

export default DiagMasivoModal;