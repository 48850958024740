import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { StyledModalcomponentMuestras } from "../../../Styled.jsx";
import { colors, fonts } from "../../../../../assets/styles/theme.ts";
import CustomButton from "../../../../Button/CustomButton.tsx";



const ModalChange = (props) => {
  const handleClose = (result) => {
    if (props.onCloseDos) {
      props.onCloseDos();
    }
  };
  const handleConfirmar = () => {
    if (props.onClick) {
      props.confirmar();
    }
  };

  return (
    <StyledModalcomponentMuestras
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "20px",
        width: "50%",
      }}
    >
      <div className="divLabel">
        <p className="titulo">CONFIRMACIÓN</p>
      </div>

      <BoxStyled>
        <Typography>
          Al cambiar el Mensaje Predefinido, el diagnóstico y las sugerencias se actualizarán automáticamente
        </Typography>
      </BoxStyled>
      <BoxFooter>
        <Typography>Confirma la operación?</Typography>
        <Box>
          <CustomButton
            label={"No"}
            className={`buttonColorDisabled`}
            onClick={() => handleClose(true)}
          />
          <CustomButton
            label={"Si"}
            className={`buttonColorDefault`}
            onClick={() => handleConfirmar(false)}
          />
        </Box>
      </BoxFooter>
    </StyledModalcomponentMuestras>
  );
};

export default ModalChange;

const BoxStyled = styled(Box)`
  font-family: ${fonts.primary};
  font-weight: 400;
  font-size: 15px;
  border-radius: 8px;
  border: 4px solid ${colors.radiantLime};
  padding: 20px;
  .span {
    font-weight: 600;
  }
`;
const BoxFooter = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  gap: 20px;
  .MuiTypography-root {
    font-family: ${fonts.primary};
    font-weight: 500;
    font-size: 16px;
  }
  .MuiBox-root {
    display: flex;
    gap: 10px;
  }
`;
