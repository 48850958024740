import React, { useReducer, useState, useEffect } from "react";
import Input from "../../../../Input/Input";
import CustomButton from "../../../../Button/CustomButton.tsx";
import MenuItem from "@mui/material/MenuItem";
import Preloader from "../../../../Preloader/Preloader.jsx";

import { StyledModalcomponentClientes } from "../../../Styled.jsx";
import {
  getEquipos,
  updatePuntoTomas,
  createPuntoTomas,
  getClientes,
  getEquiposByClientes,
  getEquipoOptionsByCliente,
  getClienteOptions,
  getPlantaOptionsWithCliente, // Agregar aquí
  getEquipoOptionsWithCliente, getEquiposOptions,
} from "../../../../../api/Clientes.js";
import {
  getComponente,
  getLubricantes,
  getComponenteOptions,
  getLubricanteOptions
} from "../../../../../api/Configuracion.js";
import Toast from "../../../../Toast/Toast.jsx";
const PuntosDeToma = (props) => {
  const propsPuntosDeToma = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos };
  };
  const { ver, editar, datos } = propsPuntosDeToma(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [clientes, setClientes] = useState([]);
  const [equipos, setEquipo] = useState([]);
  const [componentes, setComponente] = useState([]);
  const [lubricantes, setLubricantes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedOptioncli, setSelectedOptioncli] = useState(null);
  const [selectedOptionequi, setSelectedOptionequi] = useState(null);
  const [selectedOptionlub, setSelectedOptionlub] = useState(null);
  const [selectedOptioncomp, setSelectedOptioncomp] = useState(null);

  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? "",
      cliente: datos.cliente ?? "",
      clienteId: datos.clienteId ?? "",
      equipo: datos.equipoId ?? "",
      equipoId: datos.equipoId ?? "",
      codigo: datos.codigo ?? "",
      denominacion: datos.denominacion ?? "",
      lubricante: datos.lubricanteId ?? "",
      lubricanteId: datos.lubricanteId ?? "",
      componente: datos.componenteId ?? "",
      componenteId: datos.componenteId ?? "",
      tagExterno: datos.tagExterno ?? "",
      selectedOptionPla: props.datos.selectedOptionpla ?? "",
      selectedOptionEqui: props.datos.selectedOptionequi ?? "",
    }
  );

  useEffect(() => {
    const campoValido = (campo) => form[campo] !== "";

    const camposaValidar = [
      "cliente",
      "denominacion",
      "lubricante",
      "componente",
      "equipo",
    ];
    const formularioCompleto = camposaValidar.every(campoValido);

    setFormCompleto(formularioCompleto);
  }, [form]);

  useEffect(() => {
    const fetchclientes = async () => {
      const res = await getClienteOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_cli,
      }));
      setClientes(unidades);

      if (datos && !Array.isArray(datos)) {
        const matchOption = unidades.find(
          (option) => option.value === datos.clienteId
        );
        if (matchOption) {
          setSelectedOptioncli(matchOption);
        }else{
          setSelectedOptioncli({ label: datos.cliente, value: datos.clienteId, estado: "eliminado" });
        } 
        // console.log("datos: ", datos);
      }else{

      }
    };

    const fetchEquipos = async () => {
      const res = await   getEquipoOptionsWithCliente();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.description,
        cliente:item.cliente,
      }));

     // setEquipo(unidades);

      if (datos) {
        let matchOption = '';

        if(form.selectedOptionEqui!==""){
          matchOption = unidades.find(
            (option) => option.value === props.datos.selectedOptionequi.value
          );

        }else{
          matchOption = unidades.find(
          (option) => option.value === datos.equipoId
        );

        }
        if (matchOption) {
          setSelectedOptionequi(matchOption);
          setForm({ type: "SET_FIELD", field: "equipo", value: matchOption.value });
          setForm({ type: "SET_FIELD", field: "equipoId", value: matchOption.value });

          let clienteSeleccionado = {value: matchOption.cliente?.id, label: matchOption.cliente?.description}
          setSelectedOptioncli(clienteSeleccionado);
          setForm({ type: "SET_FIELD", field: "cliente", value: clienteSeleccionado.value });
        }else {
          setSelectedOptionequi({value: datos.equipoId, label: datos.equipo, estado: "eliminado"})
          // alert("No se encontro el equipo")
        }

      }
    };

    const fetchPlantas = async () => {
      const res = await   getPlantaOptionsWithCliente();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.description,
        cliente:item.cliente,
      }));
      // setEquipo(unidades);

      if (datos) {
        let matchOption = '';

        if(form.selectedOptionpla!==""){
          matchOption = unidades.find(
              (option) => option.value === props.datos.selectedOptionpla.value
          );
        }else{
          //verificar si se puede hacer con el id del cliente

        }
        if (matchOption) {

          let clienteSeleccionado = {value: matchOption.cliente?.id, label: matchOption.cliente?.description}
          setSelectedOptioncli(clienteSeleccionado);
          setForm({ type: "SET_FIELD", field: "cliente", value: clienteSeleccionado.value });
        }

      }
    };

    const fetchLubricantes = async () => {
      const res = await getLubricanteOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_lub,
      }));
      setLubricantes(unidades);

      if (datos) {
        const matchOption = unidades.find(
          (option) => option.label === datos.lubricante
        );
        if (matchOption) {
          setSelectedOptionlub(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOptionlub(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOptionlub(unidades[0]);
      }
    };

    const fetchComponentes = async () => {
      const res = await getComponenteOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_com,
      }));
      setComponente(unidades);

      if (datos) {
        const matchOption = unidades.find(
          (option) => option.label === datos.componente
        );
        if (matchOption) {
          setSelectedOptioncomp(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOptioncomp(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOptioncomp(unidades[0]);
      }
    };

    fetchclientes();
    if(ver){setSelectedOptionequi({value: datos.equipoId, label: datos.equipo})}
    if(editar){

      fetchEquiposByCliente(datos.clienteId);
      setSelectedOptionequi({value: datos.equipoId, label: datos.equipo})
    }

    if(!ver ){
      //fetchEquipos();
      if(form.selectedOptionEqui==="" && form.selectedOptionPla!==""){
        fetchPlantas();
      }else{
      }
    }
    fetchEquipos();
    fetchComponentes();
    fetchLubricantes();
  }, []);

  useEffect(() => {
    if (form.cliente !== "" && !ver && selectedOptioncli !== "" && selectedOptioncli !== null) {
      getEquipoOptionsByCliente(selectedOptioncli.value).then((res) => {
        setEquipo(
          res.data.map((res) => {
            return { label: res.desc_equi, value: res.id };
          })
        );
      });
    }
  }, [selectedOptioncli]);

  const fetchEquiposByCliente = async (id) => {
    getEquipoOptionsByCliente(id).then((res) => {
      setEquipo(
        res.data.map((res) => {
          return { label: res.desc_equi, value: res.id };
        })
      );
    });
  };

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      if (name === "cliente") {
        setSelectedOptioncli(e);
        if (value === undefined) {
          setForm({ type: "SET_FIELD", field: "cliente", value: "" });
          setForm({ type: "SET_FIELD", field: "equipo", value: "" });
        } else {
          setSelectedOptionequi("");
          setForm({ type: "SET_FIELD", field: name, value: value });
          setForm({ type: "SET_FIELD", field: "equipo", value: "" });
        }
      } else if (name === "equipo") {
        setSelectedOptionequi(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "lubricante") {
        setSelectedOptionlub(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "componente") {
        setSelectedOptioncomp(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formattedData = {
      id: form.id,
      codigo_pto: form.codigo,
      desc_pto: form.denominacion,
      abrev_pto: "PTO",
      codigo_externo_pto: form.tagExterno,
      tipo_pto_id: "1",
      equipo_id: form.equipo.toString(),
      componente_id: form.componente.toString(),
      lubricante_id: form.lubricante.toString(),
      created_by: "admin",
      created_date: new Date(),
      last_modified_by: "admin",
      status: "SI",
      eliminado: false,
      tenantId: "1",
    };

    if (editar) {
      updatePuntoTomas(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Punto modificado con exito.",
            status: "success",
            code: "edit"
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se oudo modificar el punto.",
            status: "error",
            code: "edit.err"
          });
        });
    } else {
      createPuntoTomas(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Punto creado con exito.",
            status: "success",
            code: "create"
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo crear el punto.",
            status: "error",
            code: "create.err"
          });
        });
    }
  };

  const titulo = () => {
    const titulo = ver
      ? "Ver punto de toma"
      : editar
      ? "Editar punto de toma"
      : "Crear o editar punto de toma";
    return titulo;
  };

  const fetchEquipos = async () => {
    getEquipos().then((res) => {
      setEquipo(
        res.data.items.map((res) => {
          return { label: res.desc_equi, value: res.id };
        })
      );
    });
  };


  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={"Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!selectedOptioncli || !formCompleto || (selectedOptioncli.estado && selectedOptioncli.estado === "eliminado")}
          />
        )}
      </>
    );
  };

  return (
    <StyledModalcomponentClientes>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div classname="toast">
        {" "}
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={
              "Error al " +
              (editar ? "modificar" : "crear") +
              "El punto de toma ?"
            }
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <div className="puntosDeToma">
        <div className="divLabel">
          <p className="titulo"> {titulo()} </p>
        </div>
        <div className="divInputsItem">
          <form className="formulario" onSubmit={handleSubmit}>
            <div className="divUno d-flex divs">
              <div style={{ width: "50%" }}>
                <Input
                  reactSelect
                  options={clientes}
                  value={selectedOptioncli}
                  onChange={(e) => {
                    if (e === null) {
                      console.log("onChange e: ", e);
                      setSelectedOptionequi("");
                      setEquipo([]);
                      handleChange("", "cliente");
                    } else {
                      handleChange(e, "cliente");
                      //setSelectedOptioncli(e);
                      //fetchEquiposByCliente(e.value);
                    }
                  }}
                  isDisabled={ver && true}
                  placeholder={
                    ver
                      ? "Cliente"
                      : "Seleccione el cliente"
                  }
                  isClearable
                />
                { selectedOptioncli && selectedOptioncli.estado === "eliminado" ? <p className="p-0 m-0 " style={{ margin: "0px", fontStyle: "italic", fontWeight: "bold", color: "red" }}>**cliente eliminado, elegir otro**</p> : null}
              </div>
              <div style={{ width: "50%" }}>
                <Input
                  reactSelect
                  options={equipos}
                  value={selectedOptionequi}
                  onChange={(e) => {
                    handleChange(e, "equipo");
                  }}
                  isDisabled={
                    equipos.length === 0
                  }
                  placeholder={ ver ? "Equipo" :
                    form.cliente === ""
                      ? "Seleccione un cliente primero"
                      : "Seleccione el equipo"
                  }
                />
                {selectedOptioncli && selectedOptionequi.estado === "eliminado" ? <p className="p-0 m-0 " style={{ margin: "0px", fontStyle: "italic", fontWeight: "bold", color: "red" }}>**equipo eliminado**</p> : null}
              </div>
            </div>
            <div className="divDos d-flex divs">
              {ver || editar ? <Input
                value={form.codigo}
                label="Código"
                classnamediv="divCodigo"
                className="input inputCodigo"
                variant="outlined"
                size={"medium"}
                name="codigo"
                onChange={handleChange}
                disabled={true}
              /> : <></>}
              <Input
                value={form.denominacion}
                label="Denominación"
                classnamediv="divDenominacion"
                className="input inputDenominacion"
                variant="outlined"
                size={"medium"}
                name="denominacion"
                onChange={handleChange}
                disabled={ver && true}
              />
            </div>
            <div className="divTres d-flex divs">
              <div style={{ width: "50%" }}>
                <Input
                  reactSelect
                  options={lubricantes}
                  value={selectedOptionlub}
                  onChange={(e) => {
                    handleChange(e, "lubricante");
                  }}
                  isDisabled={ver && true}
                  placeholder={ver ? "Lubricante" : "Selecciona el Lubricante"}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Input
                  reactSelect
                  options={componentes}
                  value={selectedOptioncomp}
                  onChange={(e) => {
                    handleChange(e, "componente");
                  }}
                  isDisabled={ver && true}
                  placeholder={ver ? "Componente" : "Selecciona el Componente"}
                />
              </div>
            </div>
            <div className="divCuatro divs">
              <Input
                value={form.tagExterno}
                label="TAG Externo"
                classnamediv="divTagExterno"
                className="input inputTagExterno"
                variant="outlined"
                size={"medium"}
                name="tagExterno"
                onChange={handleChange}
                disabled={ver}
              />
            </div>

            <div className="divButtonsItem">{renderButtons()}</div>
          </form>
        </div>
      </div>
    </StyledModalcomponentClientes>
  );
};

export default PuntosDeToma;
