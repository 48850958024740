import React, { useEffect } from "react";
import { colors } from "../../assets/styles/theme.ts";
import styled from "styled-components";
import {
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import ReactSelect, { components } from "react-select";
import FilterIcon from "../../assets/Icons/Buttons/Muestras/filter.svg";
import { RenderImgStyled } from "../Tables/TablesStyled.js";
const Input = (props) => {
  const [placeholderSelect, setPlaceholderSelect] = React.useState("");
  const [activeLegendReactSelect, setActiveLegendReactSelect] =
    React.useState("");
  const propsInput = (data) => {
    const className = data?.className ? data.className : "inputComponent";
    const inputProp = data?.inputProp || false;
    const positionIcon = data?.positionIcon
      ? `${data?.positionIcon}Adornment`
      : "startAdornment";
    const icon = data?.icon || null;
    const iconSelect = (
      <div htmlFor="select-icon" className="icon">
        {icon}
      </div>
    );
    const placeholder = data?.placeholder || "";
    const label = data?.label || "";
    const readOnly = data?.InputProps?.readOnly || false;
    const inputSelect = data?.inputSelect || false;
    const datosSelect = data?.datosSelect || [];
    const datosselectdefault = data?.datosselectdefault || [];
    const typeRow = data?.typeRow || "";
    const name = data?.name || "";
    const inputform = data?.inputForm || "false";
    const nameregister = data?.nameRegister || "";
    const reactSelect = data?.reactSelect || false;

    return {
      className,
      inputProp,
      positionIcon,
      icon,
      placeholder,
      readOnly,
      inputSelect,
      datosSelect,
      datosselectdefault,
      typeRow,
      iconSelect,
      label,
      name,
      inputform,
      nameregister,
      reactSelect,
    };
  };

  const {
    className,
    inputProp,
    positionIcon,
    icon,
    placeholder,
    readOnly,
    inputSelect,
    iconSelect,
    label,
    datosselectdefault,
    name,
    reactSelect,
  } = propsInput(props);

  const validTextFieldProps = { ...props };
  delete validTextFieldProps.inputProp;
  delete validTextFieldProps.positionIcon;
  delete validTextFieldProps.typeRow;
  delete validTextFieldProps.datosSelect;
  delete validTextFieldProps.inputSelect;
  const propsReactSelect = { ...props };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      zIndex: "auto",
    }),
    placeholder: (defaultStyles, state) => ({
      ...defaultStyles,
      fontFamily: "Inter",
      // color: state.isDisabled ? `${colors.blueDark}` : ${colors.blueDark},
      color: `${colors.blueDark}`,
      fontSize: "17px",
      fontWeight: "400",
      letterSpacing: "0.2px",
    }),

    control: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      height: "55px",
      zIndex: "auto",
      borderRadius: "8px",
      boxShadow: state.isFocused ? colors.turquoise : "none",
      border: props.borderColor && `4px solid ${props.borderColor}`,
      borderColor: state.isFocused || state.isHovered || activeLegendReactSelect ? colors.turquoise : colors.selectInput,
      "&:hover": {
        borderColor: !props.borderColor ? colors.turquoise : props.borderColor,
      },
      "&:focused": {
        borderColor: !props.borderColor ? colors.turquoise : props.borderColor,
      },
      "&:active": {
        borderColor: !props.borderColor ? colors.turquoise : props.borderColor,
      },

      color: state.isDisabled ? `${colors.blueDark}` : 'black',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      zIndex: "9999",

    }),
    menuList: (base) => ({
      ...base,
      maxHeight: '200px',
      overflowY: 'auto',
      "@media (max-width: 1400px)": {
        maxHeight: "130px"
      },
      "@media (max-width: 720px)": {
        maxHeight: "100px"
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? colors.turquoise
        : state.isFocused
          ? colors.turquoise
          : null,
      color: state.isSelected ? "white" : "inherit",
      cursor: "pointer",
      fontFamily: "roboto",
      ":active": {
        ...provided[":active"],
        backgroundColor: state.isSelected ? "#a28e8e" : "rgb(212, 222, 241)",
        fontFamily: "roboto",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: "roboto",
      padding: "0px 5px",
      color: state.isDisabled ? `${colors.blueDark}` : 'black',
    }),

  };
  const customStylesHeader = {
    container: (provided) => ({
      ...provided,
      zIndex: "auto",
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: "400",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#f8f7f7",
      border: "1px solid transparent",
      height: "38px",
      zIndex: "auto",
      borderRadius: "10px",
      boxShadow: state.isFocused ? "0 0 0 1px rgb(49 130 206 / 40%)" : "none",
      "&:hover": {
        border: "1px solid transparent",
      },
      "&:active": {
        border: "1px solid transparent",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      zIndex: "9999",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? colors.turquoise
        : state.isFocused
          ? colors.turquoise
          : null,
      color: state.isSelected ? "white" : "inherit",
      cursor: "pointer",
      fontFamily: "roboto",
      ":active": {
        ...provided[":active"],
        backgroundColor: state.isSelected ? "#a28e8e" : "rgb(212, 222, 241)",
        fontFamily: "roboto",
      },
    }),
    singleValue: (provided, base) => ({
      ...provided,
      ...base,
      fontFamily: "roboto",
      padding: "0px 5px",
      display: "flex",
      alignItems: "center",
    }),
  };

  useEffect(() => {
    if (props.value) {
      handleChangeReactSelect(props.value);
    }
  }, [props.value, props.onChange, setActiveLegendReactSelect]);



  const handleChangeReactSelect = (val) => {
    setActiveLegendReactSelect(val?.label ? val?.label : "");
  };
  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <RenderImgStyled img={FilterIcon} /> {children}
    </components.Control>
  );

  return (
    <Styled className={props.classnamediv ? props.classnamediv : "divInput"}>
      {reactSelect ? (
        <div
          style={{
            ...props.styledReactSelect,
            position: "relative",
            padding: "5px 0px",

          }}
        >
          {placeholder && activeLegendReactSelect?.length > 0 && (
            <div
              style={{
                background:
                  "linear-gradient(to bottom, white 60%, #F7F7F8 30%)",
                position: "absolute",
                top: "-5px",
                left: "10px",
                padding: "0px 5px",
                zIndex: 99,
                fontFamily: "inter",
                color: colors.blueDark,
                fontSize: "13px",
                fontWeight: "400",
                letterSpacing: "0.2px",
              }}
            >
              {placeholder}
            </div>
          )}
          <ReactSelect
            {...propsReactSelect}
            isClearable={props.isClearable}
            options={props.options}
            placeholder={placeholder}
            value={props.value}
            onChange={(e) => {
              handleChangeReactSelect(props.value ? props.value : e);
              if (props.onChange) {
                props.onChange(e);
              }
            }}
            styles={props.stylesHeader ? customStylesHeader : customStyles}
            components={
              props.stylesHeader
                ? {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  Control,
                }
                : { IndicatorSeparator: () => null }
            }
          />
        </div>
      ) : inputSelect ? (
        <FormControl className={className}>
          <InputLabel htmlFor="select-icon">{placeholder}</InputLabel>
          {iconSelect}
          <Select
            {...validTextFieldProps}
            value={placeholderSelect}
            label={placeholder}
            name={name}
            onChange={(e) => {
              setPlaceholderSelect(e.target.value);
              props.onChange && props.onChange(e);
            }}
            inputProps={{
              id: "select-icon",
            }}
            renderValue={placeholderSelect !== "" ? undefined : null}
          >
            {datosselectdefault &&
              datosselectdefault.map((option, key) => (
                <MenuItem key={key} value={option}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          {...validTextFieldProps}
          className={className}
          placeholder={placeholder}
          label={label}
          name={name}
          InputLabelProps={{
            shrink: props.shrink,
            style: { fontSize: '17px', color: `${colors.blueDark}`, top: "-2px", fontWeight: "400", left: "-4px", letterSpacing: "0.5px", },
          }}
          InputProps={{
            [positionIcon]:
              props.search && inputProp ? (
                <InputAdornment position="start">{icon}</InputAdornment>
              ) : null,
            endAdornment:
              props.search && props.value?.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      if (props.isClearable) {
                        props.isClearable();
                      }
                    }}
                    disableRipple
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : null,
            readOnly: readOnly,
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: colors.turquoise,
              },
              "&:hover fieldset": {
                borderColor: colors.turquoise,
              },
              "fieldset": {
                borderColor: props.value ? colors.turquoise : colors.selectInput,
              }
            },

          }}
        />
      )}
    </Styled>
  );
};

export default Input;

const Styled = styled.div`
  .Mui-disabled {
    -webkit-text-fill-color: ${colors.blueDark};
  }
  .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: ${colors.blueDark};
  }
  .MuiOutlinedInput-notchedOutline.Mui-disabled {
    fieldset {
      border-color: rgba(0, 0, 0, 0.5);
    }
  }
  .inputDefault {
  }
  .inputComponent {
    border-radius: 20px;
    background-color: #f6f8f8;
    width: 100%;
    fieldset {
      border: none;
    }
  }

  .inputSelect {
    border-radius: 20px;
    background-color: #f6f8f8;
    .icon {
      position: absolute;
      top: 10px;
      font-size: 20px;
      left: 10px;
      z-index: 1;
    }
    label {
      top: -5px;
      left: 20px;
    }
    .MuiSelect-select {
      padding: 10px 35px;
      font-size: 15px;
      font-family: Inter;
    }
    width: 100%;
    fieldset {
      border: none;
    }
  }
`;
