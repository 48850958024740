// import React, { useState, useEffect } from "react";
// import { v4 as uuidv4 } from "uuid";
// import {
//   Styled,
//   CustomButton,
//   Title,
//   DivHeader,
//   DivInputButton,
//   Input,
//   RenderImgStyled,
//   More,
//   RenderInput,
// } from "./SuperAdminStyled";
// import {
//   EditOutlined,
//   DeleteOutlineOutlined,
//   RemoveRedEyeOutlined,
//   Search,
// } from "@mui/icons-material";
// import Tables from "../../../components/Tables/Tables";
// import Modal from "../../../components/Modal/Modal";
// import Modal2 from "../../../components/Modal/Modal";
// import Toast from "../../../components/Toast/Toast";
// // import DropZone from "../../../components/DropZone/DropZone";
// import DropZoneT from "../../../components/DropZone/DropZoneTest";
// import {
//   allTenantPaginated,
//   createTenant,
//   deleteTenant,
//   updateTenant,
// } from "../../../api/auth";
// import { useForm } from "react-hook-form";
// import { TextField } from "@mui/material";
// import { CircularProgress } from "@mui/material";

// const columns = [
//   { id: "codigo", label: "Código" },
//   { id: "denominacion", label: "Denominación" },
//   { id: "logo", label: "Logo" },
//   { id: "buttons", label: "" },
// ];
// const buttons = [
//   {
//     titleToolTip: "Ver",
//     icon: <RemoveRedEyeOutlined />,
//     className: "buttonVer",
//   },
//   {
//     titleToolTip: "Editar",
//     icon: <EditOutlined />,
//     className: "buttonEditar",
//   },
//   {
//     titleToolTip: "Eliminar",
//     icon: <DeleteOutlineOutlined />,
//     className: "buttonEliminar",
//   },
// ];
// const inputGroups = [
//   {
//     className: "col",
//     inputs: [
//       { classname: "divCodigo", label: "Código", nam: "codigo" },
//       {
//         classname: "divDenominacion",
//         label: "Denominación",
//         nam: "denominacion",
//       },
//     ],
//   },
//   {
//     className: "coll",
//     inputs: [
//       { classname: "divDireccion", label: "Dirección", nam: "direccion" },
//       { classname: "divTelefono", label: "Teléfono", nam: "telefono" },
//     ],
//   },
//   {
//     className: "colll",
//     inputs: [{ classname: "divEmail", label: "Email", nam: "email" }],
//   },
//   {
//     className: "collll",
//     inputs: [
//       { classname: "divPwb", label: "Página Web", nam: "paginaWeb" },
//       {
//         classname: "divTimagen",
//         label: "Tamaño Máximo de Imágenes",
//         nam: "tamanoMaximoImagenes",
//       },
//     ],
//   },
//   {
//     className: "colllll",
//     dropZone: true,
//     inputs: [
//       {
//         classname: "divPimagenes",
//         label: "Path Imágenes",
//         nam: "pathImagenes",
//       },
//       {
//         classname: "divLmanualUsuario",
//         label: "Link Manual del Usuario",
//         nam: "manualUsuario",
//       },
//     ],
//   },
// ];
// const SuperAdmin = () => {
//   const uniqueId = uuidv4();
//   const [rows, setRows] = useState([]);
//   const [statusModal, setModalStatus] = useState(false);
//   const [paginaActual, setPaginaActual] = useState(1);
//   const [pageCount, setPageCount] = useState(1);
//   const [itemsCount, setItemsCount] = useState(1);
//   const [orderBy, setOrderBy] = useState("");
//   const [idTenant, setIdTenant] = useState("");
//   // const [idTenantt, setIdTenantt] = useState("");
//   const [searchTerm, setSearchTerm] = useState("");
//   const [success, setSuccess] = useState(false);
//   const [error, setError] = useState(false);
//   const [msjToast, setMsjToast] = useState("");
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [statusModal2, setModalStatus2] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [dataRow, setDataRow] = useState({});
//   const [valueTicket, setValueTicket] = useState("");
//   const [key, setKey] = useState(0);
//   const {
//     register,
//     handleSubmit,
//     formState: { errors, isDirty, isValid },
//     reset,
//   } = useForm();

//   useEffect(() => {
//     // if (idTenant !== "") {
//     //   handleDeleteTenant(idTenant);
//     // } else {
//     // allTenant()
//     //   .then((res) => {
//     //     setRows(res.data);
//     //   })
//     //   .catch((err) =>
//     //     console.error("Error al obtener datos de allTenant:", err)
//     //   );
//     fetchAllTenantPaginated(paginaActual, orderBy, searchTerm, "primera");
//     // }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [paginaActual, orderBy]); // Ignorando fetchAllTenantPaginated y searchTerm en la dependencia array

//   const fetchAllTenantPaginated = async (pagina, order, search, action) => {
//     const res = await allTenantPaginated(pagina, order, search);

//     // console.log("res:", res.data.items); //////////--------ACAAAAAAA--------//////////
//     setPageCount(res.data.totalPages);
//     setItemsCount(res.data.totalItems);

//     if (action === "new") {
//       if (res.data.items.length + 1 === 21) {
//         setPaginaActual(res.data.totalPages);
//       }
//     } else if (action === "delete") {
//       setKey(key + 1);
//       setMsjToast("Tenant final eliminado con exito.");
//       setSuccess(true);
//     } else {
//       if (res.data.totalPages === 0) {
//         setPaginaActual(1);
//       } else if (res.data.totalPages < paginaActual) {
//         setPaginaActual(res.data.totalPages);
//       } else {
//         setPaginaActual(paginaActual);
//       }
//     }

//     setRows(
//       res.data.items.map((item) => {
//         return {
//           ...item,
//           logo: item.logo
//             ? `${item.logo_content_type || "data:image/png;base64"},${
//                 item.logo
//               }`
//             : "",
//         };
//       })
//     );
//   };

//   const handleClickButtonTable = (value, valuesRow) => {
//     // console.log("value: ", value);
//     // console.log("valuesRow: ", valuesRow);

//     if (value !== "Eliminar") {
//       setModalStatus(true);
//       setValueTicket(value);
//       setDataRow(valuesRow);
//       setIdTenant(valuesRow?.id);
//     } else {
//       setIdTenant(valuesRow?.id);
//       setModalStatus2(true);
//     }
//   };

//   const RenderButtonsForm = () => {
//     if (valueTicket === "Ver") return null;
//     return (
//       <div className="divButtons">
//         <CustomButton
//           label="Cancelar"
//           variant="contained"
//           className="buttonColorDisabled"
//           onClick={handleClickCerrar}
//           disableRipple
//         />
//         <CustomButton
//           label="Confirmar"
//           variant="contained"
//           className="buttonColorDefault"
//           disableRipple
//           type="submit"
//           disabled={!isDirty || !isValid}
//         />
//       </div>
//     );
//   };

//   const handleClickCerrar = () => {
//     setValueTicket("");
//     reset();
//     setModalStatus(false);
//   };

//   const html = () => {
//     return (
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <RenderInput>
//           {/* {console.log(valueTicket)} */}
//           {inputGroups.map((group, index) => (
//             <div key={index} className={group.className}>
//               {group.inputs.map((input, inputIndex) => (
//                 <TextField
//                   {...register(input.nam, { required: true })}
//                   className={input.classname}
//                   size={"small"}
//                   label={input.label}
//                   key={inputIndex}
//                   defaultValue={
//                     valueTicket === "Editar" || valueTicket === "Ver"
//                       ? dataRow[input.nam]
//                       : ""
//                   }
//                   error={errors[input.nam] ? true : false}
//                   helperText={errors[input.nam] && "Este campo es requerido"}
//                 />
//               ))}
//               {/* {group.dropZone && <DropZone onFileSelected={onFileChange} />} */}
//               {group.dropZone && (
//                 <DropZoneT
//                   setImagen={setSelectedFile}
//                   imgDefault={dataRow.logo}
//                 />
//               )}
//             </div>
//           ))}
//           {RenderButtonsForm()}
//         </RenderInput>
//       </form>
//     );
//   };

//   const onSubmit = (data) => {
//     const newData = {
//       ...data,
//       id:
//         valueTicket === "Editar"
//           ? idTenant
//           : (Math.floor(Math.random() * 90) + 10).toString(),
//       logo: selectedFile?.[1] ?? "",
//       logo_content_type: selectedFile?.[0] ?? "",
//       tamañoMaximoImagenes: 1024,
//     };
//     // alert(valueTicket);
//     const promise =
//       valueTicket === "Editar" ? updateTenant(newData) : createTenant(newData);
//     promise
//       .then(() => {
//         setSuccess(true);
//         setError(false);
//         setMsjToast(
//           valueTicket === "Editar"
//             ? "Tenant editado con exito."
//             : "Tenant creado con exito."
//         );
//         fetchAllTenantPaginated(
//           paginaActual,
//           orderBy,
//           searchTerm,
//           valueTicket === "Editar" ? "edit" : "new"
//         );
//         setModalStatus(false);
//         setValueTicket("");
//       })
//       // .then((res) => {
//       //   setRows(res.data.items);
//       //   setModalStatus(false);
//       //
//       //   reset();
//       // })
//       .catch((err) => {
//         console.error(err);
//         setSuccess(false);
//         setError(true);
//         setMsjToast(
//           valueTicket === "Editar"
//             ? "Error al editar el tenant."
//             : "Error al crear el tenant."
//         );
//         setModalStatus(false);
//       });
//   };

//   const handleDeleteTenant = (tenantId) => {
//     setIsLoading(true);
//     setModalStatus2(false);
//     deleteTenant(tenantId)
//       .then(() => {
//         setIsLoading(false);
//         setIdTenant("");
//         let paginaActualTemp = paginaActual;
//         if (rows.length === 1 && paginaActual > 1) {
//           paginaActualTemp = paginaActual - 1;
//           setPaginaActual(paginaActualTemp);
//         }
//         return fetchAllTenantPaginated(
//           paginaActual,
//           orderBy,
//           searchTerm,
//           "delete"
//         );
//       })
//       .then((res) => {
//         setRows(res.data);
//       })
//       .catch((err) => console.error("Error al eliminar el tenant:", err));
//   };

//   const handleOrder = (value) => {
//     let valor;
//     if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
//       valor = value;
//     } else {
//       valor = orderBy;
//     }

//     if (valor.indexOf("-") > -1) {
//       const updatedValue = valor.replace("-", "");
//       setOrderBy(updatedValue);
//     } else {
//       const updatedValue = "-" + valor;
//       setOrderBy(updatedValue);
//     }
//   };

//   const handleBuscador = (value) => {
//     setSearchTerm(value);
//     fetchAllTenantPaginated(paginaActual, orderBy, value, "search");
//   };

//   return (
//     <Styled>
//       {isLoading && (
//         <CircularProgress
//           size={30}
//           sx={{
//             position: "fixed",
//             bottom: "0",
//             padding: "10px",
//             zIndex: "9999",
//           }}
//         />
//       )}
//       {success && (
//         <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
//       )}
//       {error && (
//         <Toast
//           key={key}
//           type={"error"}
//           title={msjToast}
//           close={() => setError(false)}
//         />
//       )}
//       <DivHeader>
//         <Title>Clientes Tenant</Title>
//         <DivInputButton>
//           <Input
//             inputProp={true}
//             positionIcon={"start"}
//             placeholder={"Buscar por código, descripción, tipo"}
//             icon={<Search />}
//             size={"small"}
//             onChange={(e) => {
//               handleBuscador(e.target.value);
//             }}
//             className="inputComponent"
//           />
//           <CustomButton
//             label="Crear Nuevo Tenant"
//             variant={"contained"}
//             className={"buttonHeaderTable"}
//             startIcon={<RenderImgStyled img={More} />}
//             onClick={() => {
//               setValueTicket("");
//               reset();
//               setModalStatus(true);
//             }}
//             disableRipple
//           />
//         </DivInputButton>
//       </DivHeader>

//       <Tables
//         typeTable={"prop"}
//         columns={columns}
//         rows={rows}
//         cRows={20}
//         totalRows={itemsCount}
//         actualpage={paginaActual}
//         pagination={true}
//         onClickSwitch={(value) => {
//           handleOrder(value);
//         }}
//         page={(e) => setPaginaActual(e + 1)}
//         pages={pageCount}
//         // valueBuscador={valueBuscador}
//         buttons={buttons}
//         onClickButtonTable={(value, valuesRow) => {
//           handleClickButtonTable(value, valuesRow);
//         }}
//       />
//       <Modal
//         html={html()}
//         isOpen={statusModal}
//         type={"default"}
//         iconX={valueTicket === "Ver"}
//         onClose={() => {
//           setModalStatus(false);
//           setValueTicket("");
//         }}
//       />

//       <Modal2
//         type="eliminar"
//         isOpen={statusModal2}
//         onClose={() => setModalStatus2(false)}
//         onClickSuccess={() => {
//           setModalStatus2(false);
//           handleDeleteTenant(idTenant);
//         }}
//       ></Modal2>
//     </Styled>
//   );
// };

// export default SuperAdmin;r

import React from 'react'

const SuperAdmin = () => {
  return (
    <div></div>
  )
}

export default SuperAdmin
