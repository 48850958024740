import React from "react";
import { styled, Box, Typography } from "@mui/material"
import { fonts } from "../../assets/styles/theme.ts";
import { RenderImgStyled } from "../Tables/TablesStyled";
import RenderImg from "../RenderImg/RenderImg.tsx";
import Remove from "./Remove.svg";



interface Props {
  label: string;
  colorBorder: string;
  svg?: string;
}
const customRow = ({ colorBorder, label, svg }: Props) => {
  return <Styled colorBorder={colorBorder}>
    <RenderImg img={svg ? svg : Remove} width={"35px"} />
    <StyledTypoGraphy>{label}</StyledTypoGraphy>
  </Styled>
}
export default customRow;

interface StyledProps {
  colorBorder?: string;
}


const Styled = styled(Box) <StyledProps>`
  border: ${({ colorBorder }) => (colorBorder && `3px solid ${colorBorder}`)};
  display: flex;
  align-items:center;
  gap: 5px;
  padding: 5px;
  border-radius: 16px;
`;
const StyledTypoGraphy = styled(Typography)`
  font-family:${fonts.primary};
  font-size: 13px;
  font-weight: 700;
  margin: 0px;
  line-height: 16.8px;
`;