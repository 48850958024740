import React, { useState, useEffect } from "react";
import Switch from "../../assets/Icons/Buttons/Table/Switch.svg";
import { colors, fonts } from "../../assets/styles/theme.ts";
import { Divider } from "@mui/material";
import {
  Styled,
  TableContainerStyled,
  TableStyled,
  TableBody,
  TableCellStyled,
  TableHead,
  LabelHeadColumn,
  TableRow,
  PaginationStyled,
  DivButtonsTable,
  IconButton,
  Tooltip,
  RenderImgStyled,
  TablePaginationStyled,
} from "./TablesStyled";
//163C5B
const Tables = (props) => {
  const propsTables = (datosTables) => {
    const cRows = datosTables?.cRows ?? 10;
    const typeTable = datosTables?.typeTable || "clientes";
    const columns = datosTables.columns && datosTables.columns;
    const rows = datosTables?.rows && datosTables?.rows;
    const pagination = datosTables?.pagination || false;
    const valueBuscador = datosTables?.valueBuscador || "";
    const buttonsTable = datosTables?.buttons || null;
    const onClickButtonTable = datosTables?.onClickButtonTable || "nada";
    const onClickSwitch = datosTables?.onClickSwitch ?? null;
    const tableColor = datosTables?.tableColor ?? false;
    return {
      cRows,
      columns,
      rows,
      pagination,
      typeTable,
      valueBuscador,
      buttonsTable,
      onClickButtonTable,
      onClickSwitch,
      tableColor,
    };
  };

  const {
    cRows,
    columns,
    rows,
    pagination,
    typeTable,
    valueBuscador,
    buttonsTable,
    onClickButtonTable,
    onClickSwitch,
    tableColor,
    totalRows,
  } = propsTables(props);

  const [page, setPage] = React.useState(0);
  const [resultadosBusqueda, setResultadosBusqueda] = useState(rows);
  const [key, setKey] = React.useState(0);
  const [rowsPerPage] = React.useState(cRows);
  useEffect(() => {
    if (props.actualpage !== undefined) {
      setPage(props.actualpage - 1);
    }
  }, [props.actualpage]);

  const handleChangePage = (event, newPage) => {
    if (props.page) {
      props.page(newPage);
    }
    setPage(newPage);
  };
  const rendersButtonTable = (value) => {
    const render = buttonsTable?.map((res, i) => {
      return (
        <Tooltip
          title={res.titleToolTip}
          arrow
          placement="top"
          className="tool"
          key={i}
        >
          <IconButton
            aria-label="fingerprint"
            color="default"
            size="small"
            className={res.className}
            onClick={() => {
              onClickButtonTable(res.titleToolTip, value);
            }}
          >
            {res.icon}
          </IconButton>
        </Tooltip>
      );
    });
    return render;
  };
  const renderDatosRows = (value, columnId, row) => {
    if (value === ",") {
      return; // refactorizar esto;
    }
    const rowClientes = () => {
      return value;
    };
    const rowProp = () => {
      return columnId === "buttons" ? (
        <DivButtonsTable>{rendersButtonTable(row)}</DivButtonsTable>
      ) : (
        value
      );
    };
    return (
      <>
        {typeTable === "clientes" && rowClientes()}
        {typeTable === "prop" && rowProp()}
      </>
    );
  };

  useEffect(() => {
    const normalizedValue = valueBuscador
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/,/g, "");
    const lowercasedValue = normalizedValue.toLowerCase();

    setResultadosBusqueda(() => {
      const rowsToSearch = rows || [];
      if (!lowercasedValue) {
        return rowsToSearch;
      }
      return (
        rowsToSearch.filter((item) =>
          Object.values(item).some(
            (field) =>
              typeof field === "string" &&
              field
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .startsWith(lowercasedValue)
          )
        ) || []
      );
    });
  }, [valueBuscador, rows]);

  return (
    <Styled className="styledTables" key={key}>
      <TableContainerStyled>
        <TableStyled stickyHeader aria-label="sticky table">
          {props.caption && <caption
            style={{ background: colors.disabled, padding: "0px" }}>
            <Divider sx={{ backgroundColor: "black" }} />
            <p style={{ fontFamily: fonts.primary, fontSize: "20px", color: colors.blueDark, padding: "0px 5px", margin: "0px" }}>
              {props.labelCaption ?? ""}
            </p>
            <Divider sx={{ backgroundColor: "black" }} />
          </caption>}
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCellStyled
                  key={column.id}
                  align={column.align}
                  className="headTable"
                >
                  <LabelHeadColumn>
                    {column.label}
                    {column.id !== "buttons" && (
                      <RenderImgStyled
                        img={Switch}
                        onClick={() => {
                          if (onClickSwitch) {
                            onClickSwitch(column.label);
                          }
                        }}
                      />
                    )}
                  </LabelHeadColumn>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {resultadosBusqueda &&
              resultadosBusqueda?.map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={rowIndex}
                    sx={{
                      backgroundColor:
                        rowIndex % 2 !== 1 ? "#ffffff" : "#F4F7F8",
                      "&:hover": {
                        backgroundColor: "#AFCCD5 !important",
                      },
                    }}
                  >
                    {columns?.map((column) => {
                      let value = row[column.id];
                      let estilo;
                      // if (column.label === "Color") {
                      //   estilo = { backgroundColor: row[column.id] };
                      // } else if (column.label === "Logo") {
                      //   estilo = { Height: "30px" };
                      // } else {
                      //   estilo = { backgroundColor: "transparent" };
                      // }

                      return (
                        <TableCellStyled
                          style={estilo}
                          key={column.id}
                          align={column.align}
                        >
                          {["Logo", "Imagen"].includes(column.label) &&
                            value &&
                            value !== "," ? (
                            <img alt="img" className="logocelda" src={value} />
                          ) : (
                            renderDatosRows(value, column.id, row)
                          )}
                        </TableCellStyled>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </TableStyled>
      </TableContainerStyled>

      {
        pagination ? (
          <TablePaginationStyled
            key={props.totalRows}
            count={props.totalRows}
            page={page}
            onPageChange={(event, newPage) => handleChangePage(event, newPage)}
            showFirstButton
            showLastButton
            rowsPerPage={rowsPerPage || 20}
            shape="rounded"
            labelRowsPerPage=""
            rowsPerPageOptions={[]}
            labelDisplayedRows={({ from, to, count }) => {
              return `Mostrando ${from}-${to} de ${count} Elementos`;
            }}
          />
        ) : null
      }
    </Styled >
  );
};

export default Tables;
