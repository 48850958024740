import React, { useReducer, useState, useEffect } from "react";
import Input from "../../../../Input/Input.jsx";
import CustomButton from "../../../../Button/CustomButton.tsx";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StyledModalcomponentMuestras } from "../../../Styled.jsx";
import {
  getClienteOptions,
  getPlantaOptions,
  updateEquipos,
  createEquipos,
  getPlantabyCliente2,
  getPuntosTomasByCliente,
  getPuntoTomas,
} from "../../../../../api/Clientes.js";
import {
  getAllOrdenByCliente,
  getAllConjuntoAnalisis,
  getAllPuntoTomasByCliente,
  getAllLubricante,
  createMuestra,
  updateMuestra,
  getOrdenesTrabajo,
} from "../../../../../api/Muestras.js";
import Preloader from "../../../../Preloader/Preloader.jsx";
import Toast from "../../../../Toast/Toast.jsx";
import dayjs from "dayjs";

const data = [{ label: "hola", value: "1" }];

const MuestrasModal = (props) => {
  const [statusModal, setModalStatus] = useState(false);
  const [labelModalNoti, setLabelModalNoti] = useState("");
  const propsMuestras = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos };
  };
  const { cliente, orden, button } = props.datos || {};
  const fromNuevaMuestra = button === "crear";

  const { ver, editar, datos } = propsMuestras(props.datos);
  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [clientes, setClientes] = useState([]);
  const [ordenes, setOrdenes] = useState([]);
  const [puntosTomas, setPuntosTomas] = useState([]);
  const [conjuntos, setConjuntos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptioncli, setSelectedOptioncli] = useState(null);
  const [selectedOptionpla, setSelectedOptionpla] = useState(null);
  const [selectedOptionorden, setSelectedOptionorden] = useState(null);
  const [selectedOptionptoma, setSelectedOptionptoma] = useState(null);
  const [selectedOptioncon, setSelectedOptioncon] = useState([]);
  const [selectedOptionlub, setSelectedOptionlub] = useState(null);
  const [statusModal2, setModalStatus2] = useState(false);
  const [lubricantes, setLubricantes] = useState([]);
  const [key, setKey] = useState(1);
  const [ordenTrabajoLog, setOrdenTrabajoLog] = useState([]);
  const [bloquearInput, setBloquearInput] = useState(ver ? true : false);
  const [fechaseleccionada, setFechaseleccionada] = useState(
    datos.fechaProgr ? dayjs(datos.fechaProgr) : null
  );
  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? "",
      codigo: datos.codigo ?? "",
      lote: datos.lote ?? "",
      denominacion: datos.denominacion ?? "",
      cliente: datos.cliente ?? "",
      clienteDesc: datos.clienteDesc ?? "",
      planta: datos.planta ?? "",
      estado: datos.estado ?? "",
      fecha: datos.fechaTomaMue ? dayjs(datos.fechaTomaMue) : null,
      // fecha: datos.fechaProgr ? dayjs(datos.fechaProgr) : null,
      punto: datos.punto ?? "",
      lubSugerido: datos.lubSugerido ?? "",
      horasUso: datos.horasUso ?? "",
      lubricante: datos.lubricante ?? "",
      horasusoLubricante: datos.horasUsoLubricante ?? "",
      responsable: datos.responsable ?? "",
      ordenTrabajoComp: datos.ordenTrabajo ?? "",
      conjuntoComp: datos.conjunto ?? "",
      puntoTomaComp: datos.puntoToma ?? "",
      conjunto: datos.conjunto?.id ?? "",
    }
  );
  // console.log(props.datos);

  useEffect(() => {
    // const campoValido = (campo) => form[campo] !== "";
    const campoValido = (campo) => form[campo] !== null && form[campo] !== "";
    const camposaValidar = [
      //"codigo",
      "fecha",
      "orden",
      "punto",
      "cliente",
      "denominacion",
      "conjunto",
      "horasusoLubricante",
      "horasUso",
      "descripcion",
      "responsable",
    ];
    const formularioCompleto = camposaValidar.every(campoValido);
    // console.log(formularioCompleto);
    setFormCompleto(formularioCompleto);
  }, [form]);

  useEffect(() => {
    fetchclientesAndOrden();
    fetchConjuntos();
    fetchLubricantes();

    // fetchOrdenTrabajoLog();
    // if (selectedOptioncli === null) {
    //   fetchclientes();
    // }

    if (editar || ver) {
      fetchPuntosTomas(); //aca voy a traer los datos de la tabla por falta de options
    }

    //getEstadoOrdenesOptions
    // fetchEstados();
  }, []);

  useEffect(() => {
    fetchConjuntos();
    fetchLubricantes();


    if (editar || ver) {
      fetchPuntosTomas();
      fetchclientesAndOrden();
    }

    else if (fromNuevaMuestra && cliente?.value) {

      getAllOrdenByCliente(cliente.value).then((res) => {
        const ordenesMap = res.data.map((o) => ({
          label: o.desc_otra,
          value: o.id,
        }));
        setOrdenes(ordenesMap);
      });

      getAllPuntoTomasByCliente(cliente.value).then((res) => {
        const puntosMap = res.data.map((pto) => ({
          label: pto.desc_pto,
          value: pto.id,
          lub: pto.desc_lub,
        }));
        setPuntosTomas(puntosMap);
      });
    }
    else {
      fetchclientesAndOrden();
    }
  }, [editar, ver, fromNuevaMuestra, cliente]);

  useEffect(() => {
    // Sólo corre si el padre envía "cliente" y "orden"


    if (cliente?.value) {
      setSelectedOptioncli(cliente);
      setForm({ type: "SET_FIELD", field: "cliente", value: cliente.value });
    }
    if (orden?.value) {
      setSelectedOptionorden(orden);
      setForm({ type: "SET_FIELD", field: "orden", value: orden.value });
    }
  }, [cliente, orden]);

  const fetchclientes = async () => {
    const res = await getClienteOptions();
    const unidades = res?.data?.map((item) => ({
      value: item.id,
      label: item.desc_cli,
    }));
    setClientes(unidades);

    if (datos) {
      const matchOption = unidades.find(
        (option) => option.label === datos.cliente
      );
      if (matchOption) {
        // console.log("matchOption", matchOption);
        setSelectedOptioncli(matchOption);
      } else if (unidades.length > 0 && editar) {
        setSelectedOptioncli(unidades[0]);
      }
    } else if (unidades.length > 0 && editar) {
      setSelectedOptioncli(unidades[0]);
    }
  };

  const fetchConjuntos = async () => {
    const res = await getAllConjuntoAnalisis();
    const unidades = res?.data?.map((item) => ({
      value: item.id,
      label: item.desc_cana,
    }));
    setConjuntos(unidades);

    if (datos) {
      const matchOption = unidades.find(
        (option) => option.value === form.conjunto
      );
      if (matchOption) {
        // console.log("matchOption", matchOption);
        setSelectedOptioncon(matchOption);
      } else if (unidades.length > 0 && editar) {
        setSelectedOptioncon(unidades[0]);
      }
    } else if (unidades.length > 0 && editar) {
      setSelectedOptioncon(unidades[0]);
    }
  };

  const fetchLubricantes = async () => {
    const res = await getAllLubricante();
    const unidades = res?.data?.map((item) => ({
      value: item.id,
      label: item.desc_lub,
    }));
    setLubricantes(unidades);

    if (datos) {
      const matchOption = unidades.find(
        (option) => option.label === datos.lubricante?.description
      );
      if (matchOption) {
        // console.log("matchOption", matchOption);
        setSelectedOptionlub(matchOption);
      } else if (unidades.length > 0 && editar) {
        setSelectedOptionlub(unidades[0]);
      }
    } else if (unidades.length > 0 && editar) {
      setSelectedOptionlub(unidades[0]);
    }
  };

  const fetchclientesAndOrden = async () => {
    const res = await getClienteOptions();
    const allCLientes = res?.data?.map((item) => ({
      value: item.id,
      label: item.desc_cli,
    }));
    setClientes(allCLientes);

    if (datos && !Array.isArray(datos)) {
      const matchOption = allCLientes.find(
        (option) => option.value === datos.cliente
      );
      if (matchOption) {
        setSelectedOptioncli(matchOption);
        getAllOrdenByCliente(matchOption.value).then((res) => {
          if (res.data.length !== 0) {
            setOrdenes(
              res.data.map((res) => {
                // console.log(JSON.stringify(res));
                if (form.ordenTrabajoComp.desc_otra === res.desc_otra) {
                  setSelectedOptionorden({
                    label: res.desc_otra,
                    value: res.id,
                  });
                }
                return { label: res.desc_otra, value: res.id };
              })
            );
          }
        });

        getAllPuntoTomasByCliente(matchOption.value).then((res) => {
          if (res.data.length !== 0) {
            setPuntosTomas(
              res.data.map((res) => {
                // console.log(JSON.stringify(res));
                if (form.puntoTomaComp.desc_pto === res.desc_pto) {
                  setSelectedOptionptoma({
                    label: res.desc_pto,
                    value: res.id,
                    lub: res.desc_lub,
                  });
                }
                if (res.desc_lub !== null) {
                  setForm({
                    type: "SET_FIELD",
                    field: "lubSugerido",
                    value: {
                      label: res.desc_lub.description,
                      value: res.desc_lub.id,
                    },
                  });
                }

                return {
                  label: res.desc_pto,
                  value: res.id,
                  lub: res.desc_lub,
                };
              })
            );
          }
        });
      }else{
        // console.log("No se pudo encontrar el cliente.")
        allCLientes.push({ label: datos.clienteDesc, value: datos.cliente, estado: "eliminado" });
        //await fetchPlantas();
        setSelectedOptioncli({ label: datos.clienteDesc, value: datos.cliente, estado: "eliminado" });
        setSelectedOptionorden({ label: datos.ordenTrabajo.desc_otra, value: datos.ordenTrabajo.id });
      }
    }
  };

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;

      if (name === "cliente") {
        // El cliente no se guarda, solo sirve para obtener las plantas.
        //        setSelectedOptionequ([]);
        setSelectedOptioncli(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "punto") {
        //REVISAR ACA
        setSelectedOptionptoma(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "conjunto") {
        //REVISAR ACA
        setSelectedOptioncon(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      } else if (name === "lubricante") {
        setSelectedOptionlub(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      }
    }
  };

  const fetchPuntosTomas = async () => {
    //const res = await getPuntoTomas();

    //  const allPuntosTomas = res?.data?.items.map((item) => ({
    //    value: item.id,
    //    label: item.desc_pto,
    //  }));

    if (datos) {
      let auxpto = {
        value: datos.puntoToma.id,
        label: datos.puntoToma.description,
      };
      let auxOrden = {
        value: datos.ordenTrabajo.id,
        label: datos.ordenTrabajo.description,
      };
      let auxConjunto = {
        value: datos.conjunto.id,
        label: datos.conjunto.description,
      };
      let auxLubricante = {
        value: datos.lubricante.id,
        label: datos.lubricante.description,
      };

      // console.log(auxpto);
      // console.log(auxOrden);
      // console.log(auxConjunto);
      // console.log(auxLubricante);
      //const matchOption = allPuntosTomas.find(
      //  (option) => option.label === datos.punto
      //);
      //if (matchOption) {
      setSelectedOptionptoma(auxpto);
      setForm({ type: "SET_FIELD", field: "punto", value: auxpto.value });
      setPuntosTomas([auxpto]);

      setSelectedOptionorden(auxOrden);
      setForm({ type: "SET_FIELD", field: "orden", value: auxOrden.value });
      setOrdenes([auxOrden]);

      setSelectedOptioncon(auxConjunto);
      setForm({
        type: "SET_FIELD",
        field: "conjunto",
        value: auxConjunto.value,
      });
      setConjuntos([auxConjunto]);

      setSelectedOptionlub(auxLubricante);
      setForm({
        type: "SET_FIELD",
        field: "lubricante",
        value: auxLubricante.value,
      });
      setLubricantes([auxLubricante]);

      //}
    }
  };

  const fetchOrdenes = async () => {
    const res = await getOrdenesTrabajo();

    const allPuntosTomas = res?.data?.items.map((item) => ({
      value: item.id,
      label: item.desc_pto,
    }));
    setPuntosTomas(allPuntosTomas);

    if (datos) {
      // console.log(datos.punto);
      const matchOption = allPuntosTomas.find(
        (option) => option.label === datos.punto
      );
      if (matchOption) {
        setSelectedOptionptoma(matchOption);
      }
    }
  };

  const handleChangeCliente = (e) => {
    setSelectedOptionorden(null);
    setOrdenes([]);
    setSelectedOptionlub(null);
    setForm({ type: "SET_FIELD", field: "orden", value: "" });

    setSelectedOptionptoma(null);
    setPuntosTomas([]);
    setForm({ type: "SET_FIELD", field: "punto", value: "" });

    setSelectedOptioncli(e);
    setForm({ type: "SET_FIELD", field: "cliente", value: e.value });

    //if (form.cliente !== "" && !ver && selectedOptioncli !== null) {
    getAllOrdenByCliente(e.value).then((res) => {
      setOrdenes(
        res.data.map((res) => {
          return { label: res.desc_otra, value: res.id };
        })
      );
    });

    getAllPuntoTomasByCliente(e.value).then((res) => {
      setPuntosTomas(
        res.data.map((res) => {
          return { label: res.desc_pto, value: res.id, lub: res.desc_lub };
        })
      );
    });
    // }
  };

  const handleChangePunto = (e) => {
    setSelectedOptionptoma(e);
    setForm({ type: "SET_FIELD", field: "punto", value: e.value });
    if (e.lub !== null) {
      setForm({
        type: "SET_FIELD",
        field: "lubSugerido",
        value: { label: e.lub.description, value: e.lub.id },
      });
    }
    let selectedLub = e.lub;

    //if (form.cliente !== "" && !ver && selectedOptioncli !== null) {

    // }
  };

  const handleChangeOrden = (e) => {
    setSelectedOptionorden(e);
    setForm({ type: "SET_FIELD", field: "orden", value: e.value });

    //if (form.cliente !== "" && !ver && selectedOptioncli !== null) {

    // }
  };
  const handleChangeCalendar = (e, nameCalendar) => {
    // alert(e)
    const name = nameCalendar;
    setForm({ type: "SET_FIELD", field: name, value: e });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formattedData = {
      desc_mue: form.denominacion,
      fecha_toma_mue: form.fecha,
      fecha_recepcion_mue: "2024-10-18T10:00:00Z",
      fecha_recepcion_mue_str: "18/10/2024",
      fecha_analisis_mue: "2024-10-19T10:00:00Z",
      horas_lubricante_mue: form.horasusoLubricante,
      horas_equipo_mue: form.horasUso,
      fecha_analisis_mue_str: "19/10/2024",
      diagnostico_mue: "Diagnóstico preliminar",
      sugerencias_mue: "x",
      estado_final_mue: "x",
      estado_mue: "x",
      cant_imagenes_mue: 5,
      no_publicar_mue: "No",
      cant_resultados_mue: 3,
      //estado_final_id: 1,
      punto_toma_id: parseInt(form.punto),
      lubricante_id: parseInt(form.lubricante),
      responsable_toma_mue: form.responsable,
      conjunto_analisis_id: parseInt(form.conjunto), //form.conjunto,
      orden_trabajo_id: parseInt(form.orden), //form.orden,
      last_modified_date: "2024-10-17T12:00:00Z",
      status: "SI",
      created_by: "usuario_creador",
      created_date: "2024-10-17T12:00:00Z",
      tenantId: "1",
      eliminado: false,
    };

    // console.log(JSON.stringify(formattedData));

    // return;

    if (form.lubricante === null || form.lubricante === "") {
      formattedData.lubricante_id = form.lubSugerido.value;
    } else {
      formattedData.lubricante_id = form.lubricante;
    }

    if (editar) {
      // console.log(JSON.stringify(formattedData));
      formattedData.id = form.id;
      updateMuestra(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Muestra modificada con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se Pudo modificar la Muestra.",
            status: "error",
          });
        });
    } else {
      createMuestra(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Muestra creada con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo crear la Muestra.",
            status: "error",
          });
        });
      // console.log(JSON.stringify(formattedData));
    }
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label="Cancelar"
          variant="contained"
          className={"buttonColorDisabled"}
        />

        {!ver && (
          <CustomButton
            label={"Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!selectedOptioncli || !selectedOptionorden || !formCompleto || (selectedOptioncli.estado && selectedOptioncli.estado === "eliminado")}
          />
        )}
      </>
    );
  };

  const titulo = () => {
    const titulo = ver ? "Ver Muestras" : "Crear o editar Muestras";
    return titulo;
  };

  return (
    <StyledModalcomponentMuestras
      sx={{
        width: "70%",
        "@media (max-width: 768px)": {
          width: "95%",
          overflowY: "scroll",
          height: "70%",
        },
      }}
    >
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />
      <div classname="toast">
        {success ? (
          <Toast title={msjToast} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={
              "Error al " + (editar ? "modificar" : "crear") + "la orden ?"
            }
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <div className="muestras">
        <div className="divLabel">
          <p className="titulo"> {titulo()} </p>
        </div>
        <form className="formulario" onSubmit={handleSubmit}>
          <div className="columna">
            <div className="a">
              <div style={{ width: "50%" }}>
                <Input
                  isLoading={selectedOptioncli === null && editar}
                  reactSelect
                  options={clientes}
                  value={selectedOptioncli}
                  onChange={(e) => {
                    handleChangeCliente(e, "cliente");
                  }}
                  isDisabled={ver || fromNuevaMuestra}
                  placeholder={
                    selectedOptioncli ? "Cliente" : "Seleccione el cliente"
                  }
                />
                {selectedOptioncli && selectedOptioncli.estado === "eliminado" ? <p className="p-0 m-0 " style={{ margin: "0px", fontStyle: "italic", fontWeight: "bold", color: "red" }}>**cliente eliminado, elegir otro**</p> : null}
              </div>
              <div style={{ width: "50%" }}>
                <Input
                  isLoading={selectedOptionorden === null && editar}
                  reactSelect
                  options={ordenes}
                  value={selectedOptionorden}
                  onChange={(e) => {
                    handleChangeOrden(e, "orden");
                  }}
                  isDisabled={  ver ||
                      selectedOptioncli?.estado === "eliminado" ||
                      fromNuevaMuestra}
                  placeholder={
                    selectedOptionorden ? "Orden" : "Seleccione la orden"
                  }
                />
                {selectedOptioncli && selectedOptioncli.estado === "eliminado" ? <p className="p-0 m-0 " style={{ margin: "0px", fontStyle: "italic", fontWeight: "bold", color: "red" }}>**orden eliminada, elegir cliente**</p> : null}
              </div>
            </div>
            <div className="b">
              <Input
                isLoading={selectedOptionptoma === null && editar}
                reactSelect
                options={puntosTomas}
                value={selectedOptionptoma}
                onChange={(e) => {
                  handleChangePunto(e, "punto");
                }}
                isDisabled={ver && true}
                placeholder={
                  selectedOptionptoma
                    ? "Punto de toma"
                    : "Seleccione el punto de toma"
                }
              />
              <Input
                isLoading={selectedOptioncon === null && editar}
                reactSelect
                options={conjuntos}
                value={selectedOptioncon}
                onChange={(e) => {
                  handleChange(e, "conjunto");
                }}
                isDisabled={ver && true}
                placeholder={
                  selectedOptioncon ? "Conjunto" : "Seleccione el conjunto"
                }
              />
            </div>
            <div className="c">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker", "DatePicker", "DatePicker"]}
                >
                  <DatePicker
                    disabled={ver}
                    className="inputDate"
                    label="Fecha Toma de Muestra"
                    placeholder="Fecha Toma de Muestra"
                    value={form.fecha}
                    slotProps={
                      <Input
                        className="input inputDate"
                        variant="outlined"
                        size={"medium"}
                      />
                    }
                    onChange={(e) => {
                      handleChangeCalendar(e, "fecha");
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <Input
                classnamediv="divInput"
                className="input inputDescripcion"
                label="Responsable Toma de Muestra"
                name="responsable"
                variant="outlined"
                size={"medium"}
                disabled={ver}
                value={form.responsable}
                onChange={handleChange}
              />
            </div>
            <div className="d">
              <Input
                classnamediv="divInputL divInput"
                className="input inputDescripcion"
                label="Horas de uso de Equipo"
                name="horasUso"
                variant="outlined"
                size={"medium"}
                disabled={ver}
                value={form.horasUso}
                onChange={handleChange}
              />

              {!ver && (
                <Input
                  value={form.lubSugerido.label}
                  label="Lubricante del Punto de Toma (sugerido)"
                  placeholder="Lubricante del Punto de Toma (sugerido)"
                  classnamediv="divInputL divInput"
                  className="input inputDescripcion"
                  variant="outlined"
                  size={"medium"}
                  name="lubSugerido"
                  disabled={true}
                  onChange={handleChange}
                  shrink
                />
              )}
            </div>
            <div className="e">
              <Input
                classnamediv="divInputL divInput"
                className="input inputDescripcion"
                label="Horas de uso de Lubricante"
                name="horasusoLubricante"
                variant="outlined"
                size={"medium"}
                disabled={ver}
                value={form.horasusoLubricante}
                onChange={handleChange}
              />

              <Input
                classnamediv="divInputL divInput"
                className="input inputDescripcion"
                isLoading={selectedOptionlub === null && editar}
                reactSelect
                options={lubricantes}
                value={selectedOptionlub}
                onChange={(e) => {
                  handleChange(e, "lubricante");
                }}
                isDisabled={ver && true}
                placeholder={
                  selectedOptionlub ? "Lubricante" : "Seleccione lubricante"
                }
              />
            </div>
            <div className="f">
              {ver && (
                <Input
                  classnamediv="divInputL divInput"
                  className="input inputDescripcion"
                  label="Codigo de la Muestra"
                  name="codigo"
                  variant="outlined"
                  size={"medium"}
                  disabled={ver}
                  value={form.codigo}
                  onChange={handleChange}
                />
              )}
              <Input
                classnamediv="divInputL divInput"
                className="input inputDescripcion"
                label="Descripcion"
                name="denominacion"
                variant="outlined"
                size={"medium"}
                disabled={ver}
                value={form.denominacion}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="divButtonsItem">{renderButtons()}</div>
        </form>
      </div>
    </StyledModalcomponentMuestras>
  );
};

export default MuestrasModal;
